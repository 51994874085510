import moment from "moment";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { emailOtpVerify, emailVerifyRequest, resetPassword } from "constant";
import toast, { Toaster } from "react-hot-toast";
import { Company, Timer, User } from "pages/types";
import * as Popover from "@radix-ui/react-popover";
import { VerifyOtp } from "components";
import { getMinutes } from "utils/timeUtils";
import { useNavigate } from "react-router-dom";
import { RiEyeCloseLine } from "react-icons/ri";
import { RiEyeFill } from "react-icons/ri";

function Profile({
  companyProfileData,
  userProfileData,
  afterAnyServerAction,
}: {
  companyProfileData: Company;
  userProfileData: User;
  afterAnyServerAction: () => any;
}) {
  // Demo profile detail
  console.log(userProfileData);
  console.log("company profile details", companyProfileData);
  const [otpExpireTime, setOtpExpireTime] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    const localOtpMap = JSON.parse(
      localStorage.getItem("otp-time-map") || "{}"
    );
    if (
      localOtpMap?.emailVerify &&
      localOtpMap?.emailVerify[userProfileData?.email]
    )
      setOtpExpireTime(localOtpMap?.emailVerify[userProfileData?.email]);
  }, [userProfileData]);

  const { mutate: mutateEmailVerify, isLoading: isVerifyingEmail } =
    useMutation({
      mutationFn: emailVerifyRequest,
      onSuccess: (data) => {
        setOtpExpireTime(data?.data?.otpExpireTime);
        localStorage.setItem(
          "otp-time-map",
          JSON.stringify({
            emailVerify: {
              [userProfileData?.email]: data?.data?.otpExpireTime,
            },
          })
        );
        toast.success("Email successfully added");
        afterAnyServerAction();
      },
      onError: () => {
        toast.error("Unable to add email");
      },
    });

  const { mutate: mutateEmailOtpVerify, isLoading: isVerifyingEmailOtp } =
    useMutation({
      mutationFn: emailOtpVerify,
      onSuccess: () => {
        toast.success("Email successfully verified");
        localStorage.setItem(
          "otp-time-map",
          JSON.stringify({
            emailVerify: {},
          })
        );
        afterAnyServerAction();
        setShowModal(false);
        const urlParams = new URLSearchParams(window.location.search);

        if (urlParams.has("redirect")) {
          const redirectUrl = urlParams.get("redirect");
          navigate(redirectUrl, { state: "buy-tokens" });
        }
      },
      onError: () => {
        toast.error("Unable to verify email");
      },
    });

  const { mutate: mutateResetPassword, isLoading: isResettingPassword } =
    useMutation({
      mutationFn: resetPassword,
      onSuccess: (data) => {
        toast.success("password changed successfully");
      },
      onError: () => {
        toast.error("Unable to change password");
      },
    });

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className="all-verified-documents-wrap flex p-7 mt-8 flex-col">
        {/* Header */}
        <div className="header-info flex justify-between items-center">
          <h4>My Profile Details</h4>
        </div>

        {/* Details */}
        <ProfileDetail title="Full Name" content={userProfileData.name} />

        <ProfileDetail title="Contact Phone" content={userProfileData.userId} />
        <ProfileDetail title="Role" content={userProfileData.role} />
        <div className="flex items-center profile-details">
          <div className="w-1/4">
            <p>Email</p>
          </div>
          {userProfileData?.email?.length ? (
            <h2>
              {userProfileData.email}{" "}
              {userProfileData.isEmailVerified ? (
                <>
                  <span className="verified ml-1">Verified</span>
                  <br />
                  <button
                    onClick={() => setShowModal(true)}
                    className="blue-bt mt-2"
                  >
                    Change email
                  </button>
                </>
              ) : (
                <button onClick={() => setShowModal(true)} className="blue-bt">
                  Verify email
                </button>
              )}
            </h2>
          ) : (
            <button onClick={() => setShowModal(true)} className="blue-bt">
              {" "}
              Add email
            </button>
          )}
        </div>

        <PasswordUpdation
          onPasswordUpdate={(data) => {
            mutateResetPassword({
              newPassword: data.newPassword,
              oldPassword: data.oldPassword,
            });
          }}
        />
      </div>

      {showModal && (
        <div className="email-change-modal">
          <div className="modal-inner">
            <EmailAddition
              email={userProfileData?.email}
              isEmailVerified={userProfileData?.isEmailVerified}
              otpExpiresOn={otpExpireTime}
              isVerifyingEmail={isVerifyingEmail}
              onEmailAddition={(newEmail) => {
                mutateEmailVerify({
                  email: newEmail,
                });
              }}
              onOtpVerification={({ email, otp }) => {
                mutateEmailOtpVerify({
                  otp: otp.toString(),
                });
              }}
              onCancel={() => {
                setShowModal(false);
              }}
            />
          </div>
        </div>
      )}

      <Toaster
        toastOptions={{
          success: {
            duration: 3000,
          },
        }}
      />
    </>
  );
}

interface ProfileDetailProps {
  title: string;
  content: string;
  verified?: boolean;
}
export function ProfileDetail(props: ProfileDetailProps) {
  return (
    <div className="flex items-center profile-details gap-2">
      <div className="w-1/4">
        <p>{props.title}</p>
      </div>
      <h2>
        {props.content}{" "}
        {props.verified ? <span className="verified ml-1">verified</span> : ""}
      </h2>
    </div>
  );
}

interface EmailAdditionProps {
  email: string;
  onOtpVerification: ({ email, otp }: { email: string; otp: number }) => any;
  onCancel: () => any;
  onEmailAddition?: (newEmail: string) => any;
  isEmailVerified: any;
  otpExpiresOn: any;
  isVerifyingEmail: any;
}
export function EmailAddition(props: EmailAdditionProps) {
  const [email, setEmail] = useState(props?.email || "");
  const [codeVerification, setCodeVerification] = useState(false);
  const { isVerifyingEmail } = props;

  function handleEmailVerification() {
    console.log(`Send email with a verification code to ${email}`);
    setCodeVerification(true);
    props.onEmailAddition(email);
  }

  const otpValidTill = (props?.otpExpiresOn - Date.now()) / 1000;

  return (
    <div className="flex flex-col">
      <div className="mod-head">
        <span>Add Email</span>
      </div>
      {codeVerification || (otpValidTill > 0 && !props.isEmailVerified) ? (
        <div className="flex flex-col p-6 pb-2 gap-5">
          <VerifyOtp
            isLoading={false}
            otpExpireMinutes={Math.floor(otpValidTill / 60)}
            otpExpireSeconds={Math.floor(otpValidTill % 60)}
            resendOtpHandler={() => handleEmailVerification()}
            submitHandler={(otp) => {
              props.onOtpVerification({ email: email, otp: otp });
            }}
            onTimerEnd={() => {
              setCodeVerification(false);
            }}
          />
        </div>
      ) : isVerifyingEmail ? (
        <div className="text-white">Sending OTP</div>
      ) : (
        <div className="flex flex-col p-6 pb-2 gap-5">
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="add-email text-white ps-3 pr-3"
          />
          <div className="flex items-center justify-center">
            <button
              className="blue-bt"
              onClick={() => {
                setCodeVerification(true);
                handleEmailVerification();
              }}
            >
              send verification code
            </button>
          </div>
        </div>
      )}

      <div className="mod-body flex flex-col pt-0">
        <span className="mod-title mb-2 text-center">
          {codeVerification
            ? "Check your Inbox, we've sent a verification to the email."
            : "We will send a verification code"}
        </span>
      </div>
      <div className="mod-foot flex justify-end gap-2">
        <button className="mod-close" onClick={props.onCancel}>
          Close
        </button>
      </div>
    </div>
  );
}

interface PasswordUpdationProps {
  onPasswordUpdate: ({
    oldPassword,
    newPassword,
  }: {
    oldPassword: string;
    newPassword: string;
  }) => any;
}
function PasswordUpdation(props: PasswordUpdationProps) {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [resetPassword, setResetPassword] = useState(false);
  const [isPasswordNotSame, setIsPasswordNotSame] = useState(false);

  const [isPasswordValidating, setIsPasswordValidating] = useState(false);

  const [isCurrentPasswordVisible, setIsCurrentPasswordVisible] =
    useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);

  // TODO? initial state of this would be false waiting for server integration
  const [isCurrentPasswordWrong, setIsCurrentPasswordWrong] = useState(false);

  function toggleResetPassword() {
    setResetPassword((state) => !state);
  }
  function handlePasswordChange() {
    if (confirmPassword !== newPassword) {
      return setIsPasswordNotSame(true);
    }

    if (!currentPassword || !newPassword || !confirmPassword) {
      return toast.error("Please enter all the details");
    }

    console.log(`update the password with the new password ${newPassword}`);
    props.onPasswordUpdate({
      newPassword: newPassword,
      oldPassword: currentPassword,
    });
    setResetPassword(false);
  }

  return (
    <>
      {resetPassword ? (
        <div className="pb-5">
          <div className="reset-pass justify-between">
            <div className="mb-2">
              <p className="mb-1">Current password</p>
              <input
                className="w-1/3"
                type={isCurrentPasswordVisible ? "" : "password"}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />

              <button
                onClick={() => setIsCurrentPasswordVisible((state) => !state)}
                className="ml-4"
              >
                {isCurrentPasswordVisible ? <RiEyeFill /> : <RiEyeCloseLine />}
              </button>

              {isCurrentPasswordWrong && (
                <span className="passinfo">Entered password is wrong</span>
              )}
            </div>

            <div className="mb-2">
              <p className="mb-1">New password</p>
              <input
                className="w-1/3"
                type={isNewPasswordVisible ? "" : "password"}
                onChange={(e) => setNewPassword(e.target.value)}
                onInput={() => {
                  setIsPasswordNotSame(false);
                }}
                onBlur={() => setIsPasswordValidating(false)}
                onFocus={() => setIsPasswordValidating(true)}
              />
              <button
                onClick={() => setIsNewPasswordVisible((state) => !state)}
                className="ml-4"
              >
                {isNewPasswordVisible ? <RiEyeFill /> : <RiEyeCloseLine />}
              </button>
            </div>

            <div className="">
              <p className="mb-1">Confirm password</p>
              <input
                className="w-1/3"
                type={isConfirmPasswordVisible ? "" : "password"}
                onChange={(e) => setConfirmPassword(e.target.value)}
                onInput={() => {
                  setIsPasswordNotSame(false);
                }}
              />
              <button
                onClick={() => setIsConfirmPasswordVisible((state) => !state)}
                className="ml-4"
              >
                {isConfirmPasswordVisible ? <RiEyeFill /> : <RiEyeCloseLine />}
              </button>
            </div>
          </div>
          <div
            className="flex flex-row items-center justify-center"
            style={{ height: "1rem" }}
          >
            {isPasswordNotSame && (
              <span className="passinfo">
                New password should match the Confirmation password
              </span>
            )}
          </div>
          <div className="flex gap-6 justify-end w-1/3">
            <button className="blue-bt" onClick={handlePasswordChange}>
              Submit
            </button>
            <button className="cancel-text border px-5 border-neutral-400 rounded" onClick={toggleResetPassword}>
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <ProfileDetailMethod title="Password">
          <div className="flex flex-col">
            <div>**********</div>
            <button className="sign-in-method-bt" onClick={toggleResetPassword}>
              Reset Password
            </button>
          </div>
        </ProfileDetailMethod>
      )}
    </>
  );
}

interface ProfileDetailMethodProps {
  title: string;
  value?: string;
  children?: any;
}
function ProfileDetailMethod(props: ProfileDetailMethodProps) {
  return (
    <div className="flex sign-in-methods-box border-none">
      <div className="sign-in-methods-list w-1/4">
        <h4>{props.title}</h4>
        <p>{props.value}</p>
      </div>

      <div>{props.children}</div>
    </div>
  );
}

export default Profile;
