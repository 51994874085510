import { Company } from "pages/types";
import { ProfileDetail } from "./ProfileDetails";
import moment from "moment";

function CompanyDetails({
  profileData,
}: {
  profileData: Company;
  isUpdating: boolean;
}) {
  return (
    <div className="">
      <div className="all-verified-documents-wrap flex p-7 mt-8 flex-col">
        {/* Header */}
        <div className="header-info flex justify-between items-center">
          <h4>Company Details</h4>
        </div>

        {/* Details */}
        <ProfileDetail title="Full Name" content={profileData.name} />
        <ProfileDetail
          title="Joined On"
          content={moment(profileData.createdAt).format("DD/MM/YYYY")}
        />
        <ProfileDetail title="Company" content={profileData.companyId} />
        <ProfileDetail title="Service Type" content={profileData.type} />
        <ProfileDetail title="Contact Phone" content={profileData.adminId} />

        <ProfileDetail
          title="Location"
          content={`${profileData.address}, ${profileData.state}, ${profileData.zip}`}
        />
        <ProfileDetail title="Address" content={profileData.address} />
      </div>
    </div>
  );
}

export default CompanyDetails;
