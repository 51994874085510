import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useQuery } from "react-query";
import {
  LoginPage,
  HomePage,
  NoPageFound,
  SignupPage,
  AadharVerify,
  PanVerify,
  DLVerify,
  ManualVerify,
  CompanyCreatePage,
  SelfVerifyPage,
  DocumentsPage,
  CustomersPage,
  StaffsPage,
  AccountSettingsPage,
  WalletPage,
  VerificationLinkPage,
  CouponDetails,
  Generatecoupon,
  CustomerDetailsPage,
  EndUserPage,
  CreateResellers,
  SuperAdminHomePage,
  Companies,
  CreateCouponPage,
  AllTransactionsPage,
  CashPayment,
  CashPaymentRequests,
} from "pages";
import { AuthWrapper } from "components";
import "@radix-ui/themes/styles.css";
import { Theme } from "@radix-ui/themes";
import { ForgotPassword } from "pages/ForgotPassword";
import { queryKeys, userDetails, userRoles } from "constant";
import SalesHome from "pages/SalesHome";
import SalesAccount from "pages/SalesAccount";
import { HashLoader } from "react-spinners";

function App() {
  const { data: userData, isLoading: isUserDataLoading }: any = useQuery(
    [queryKeys.USER_DETAILS],
    userDetails
  );

  const role = userData?.data?.role;

  const HomeComponent = () => {
    switch (role) {
      case userRoles.SUPER_ADMIN:
        return <SuperAdminHomePage />;
      case userRoles.RESELLER:
        return <SalesHome />;
      case userRoles.ADMIN:
        return <HomePage />;
      default:
        return (
          <div className="flex h-[90vh] w-full items-center justify-center">
            <HashLoader color="#36d7b7" size="100" />
          </div>
        );
    }
  };

  return (
    <Theme appearance="dark">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AuthWrapper />}>
            <Route index element={<HomeComponent />} />
            <Route path="verify" element={<HomePage />} />
            <Route path="login" element={<LoginPage />} />
            <Route path="signup" element={<SignupPage />} />
            <Route path="company/create" element={<CompanyCreatePage />} />
            <Route path="verify/aadhar" element={<AadharVerify />} />
            <Route path="verify/aadhar-otp" element={<AadharVerify />} />
            <Route path="verify/pan" element={<PanVerify />} />
            <Route path="verify/dl" element={<DLVerify />} />
            <Route path="verify/manual" element={<ManualVerify />} />
            <Route path="documents" element={<DocumentsPage />} />
            <Route path="customers" element={<CustomersPage />} />
            <Route path="customer-details" element={<CustomerDetailsPage />} />
            <Route path="staffs" element={<StaffsPage />} />
            <Route path="account-settings" element={<AccountSettingsPage />} />
            <Route path="gift-request" element={<CashPayment />} />
            <Route path="cash-requests" element={<CashPaymentRequests />} />
            <Route path="wallet" element={<WalletPage />} />
            <Route
              path="verification-link"
              element={<VerificationLinkPage />}
            />
            <Route path="coupon-details" element={<CouponDetails />} />
            <Route path="generate-coupon" element={<Generatecoupon />} />
            <Route path="sales-home" element={<SalesHome />} />
            <Route path="sales-account" element={<SalesAccount />} />
            <Route path="resellers" element={<CreateResellers />} />
            <Route path="companies" element={<Companies />} />
            <Route path="create-coupons" element={<CreateCouponPage />} />
            <Route path="all-transactions" element={<AllTransactionsPage />} />
          </Route>
          <Route path="enduser/:linkId" element={<EndUserPage />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="*" element={<NoPageFound />} />
        </Routes>
      </BrowserRouter>
    </Theme>
  );
}

export default App;
