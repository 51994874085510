import { handleServerResponse } from "./middleWares";

export const apiUrl = "https://prod-api.kyctracker.in";

const createUser = async ({ queryKey }) => {
  return (
    await fetch(`${apiUrl}/user/signup`, {
      method: "POST",
      body: JSON.stringify(queryKey[1]),
    })
  ).json();
};

const loginUser = async ({ queryKey }) => {
  return (
    await fetch(`${apiUrl}/user/login`, {
      method: "POST",
      body: JSON.stringify(queryKey[1]),
    })
  ).json();
};

interface VerifyUserArgs {
  otp: Number;
}
const verifyUser = async ({ otp }: VerifyUserArgs) => {
  const authToken = localStorage.getItem("accessToken");
  return (
    await fetch(`${apiUrl}/otp-verify/signup`, {
      method: "POST",
      body: JSON.stringify({ otp: otp }),
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  ).json();
};

const userDetails = async () => {
  const authToken = localStorage.getItem("accessToken");
  let result;

  try {
    const response = await fetch(`${apiUrl}/private-user/details`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    result = response.json();
  } catch (error) {
    console.log("There was an error", error);
  }

  return result;
};

const forgotPasswordRequest = async (body: { phone: string }) => {
  return (
    await fetch(`${apiUrl}/user/forgot-password-request`, {
      method: "POST",
      body: JSON.stringify(body),
    })
  ).json();
};

const forgotPasswordVerify = async (body: {
  phone: string;
  otp: string;
  newPassword: string;
}) => {
  return (
    await fetch(`${apiUrl}/user/forgot-password-verify`, {
      method: "POST",
      body: JSON.stringify(body),
    })
  ).json();
};

const emailVerifyRequest = async (body: { email: string }) => {
  const authToken = localStorage.getItem("accessToken");
  return (
    await fetch(`${apiUrl}/private-user/email-verify-request`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  ).json();
};

const emailOtpVerify = async (body: { otp: string }) => {
  const authToken = localStorage.getItem("accessToken");
  return (
    await fetch(`${apiUrl}/otp-verify/email`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  ).json();
};

const resetPassword = async (body: {
  oldPassword: string;
  newPassword: string;
}) => {
  const authToken = localStorage.getItem("accessToken");
  return (
    await fetch(`${apiUrl}/private-user/reset-password`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  ).json();
};

// Company endpoints ---------------------------------------
const createCompany = async ({ queryKey }) => {
  const authToken = localStorage.getItem("accessToken");
  return (
    await fetch(`${apiUrl}/company/create`, {
      method: "POST",
      body: JSON.stringify(queryKey[1]),
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  ).json();
};

export interface GetCompanyArgs {
  companyId: string;
}
const getCompany = async ({ queryKey }) => {
  const authToken = localStorage.getItem("accessToken");
  return (
    await fetch(`${apiUrl}/company/get`, {
      method: "POST",
      body: JSON.stringify(queryKey[1]),
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  ).json();
};

export interface UpdateCompanyArgs {
  companyId: string;
  updateObj: any;
}
const updateCompany = async (body) => {
  const authToken = localStorage.getItem("accessToken");
  return (
    await fetch(`${apiUrl}/company/update`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  ).json();
};

const getPublicLinkDetails = async ({ queryKey }) => {
  return (
    await fetch(`${apiUrl}/document-entries-public/self-verify-link-details`, {
      method: "POST",
      body: JSON.stringify(queryKey[1]),
    })
  ).json();
};

// const documentEntry = async (body) => {
//   const authToken = localStorage.getItem("accessToken");
//   return (
//     await fetch(`${apiUrl}/document-entries-public/create-digiurl`, {
//       method: "POST",
//       body: JSON.stringify(body),
//       headers: {
//         Authorization: `Bearer ${authToken}`,
//       },
//     })
//   ).json();
// };

const documentEntry = async (body) => {
  const authToken = localStorage.getItem("accessToken");
  return (
    await fetch(`${apiUrl}/document-entries/entry`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  ).json();
};

const manualDocumentEntry = async (body) => {
  const authToken = localStorage.getItem("accessToken");
  return (
    await fetch(`${apiUrl}/document-entries/entry/manual`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  ).json();
};

const documentPublicEntry = async (body) => {
  return (
    await fetch(`${apiUrl}/document-entries-public/create`, {
      method: "POST",
      body: JSON.stringify(body),
    })
  ).json();
};

async function getDocumentEntries({ queryKey }) {
  return handleServerResponse(
    `${apiUrl}/document-entries/get-all`,
    queryKey[1]
  );
}

// Customer endpoints ---------------------------------------------
export interface DeleteCustomerArgs {
  companyId: string;
  customerId: string;
}
const deleteCustomer = async ({ queryKey }) => {
  const authToken = localStorage.getItem("accessToken");
  return (
    await fetch(`${apiUrl}/company/customer/delete`, {
      method: "POST",
      body: JSON.stringify(queryKey[1]),
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  ).json();
};

export interface UpdateCustomerArgs {
  companyId: string;
  customerId: string;
  meta: any;
  returnDate: number;
}
const updateCustomer = async (body) => {
  const authToken = localStorage.getItem("accessToken");
  return (
    await fetch(`${apiUrl}/company/customer/update`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  ).json();
};

// Staff endpoints -------------------------------------------------
export interface GetStaffsArgs {
  companyId: string;
}
const getStaffs = async ({ queryKey }) => {
  const authToken = localStorage.getItem("accessToken");
  return (
    await fetch(`${apiUrl}/company/staffs/get`, {
      method: "POST",
      body: JSON.stringify(queryKey[1]),
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  ).json();
};

export interface CreateStaffArgs {
  phone: string;
  name: string;
  password: string;
  companyId: string;
  email: string;
  meta: any;
}
const createStaff = async ({ queryKey }) => {
  const authToken = localStorage.getItem("accessToken");
  return (
    await fetch(`${apiUrl}/company/staff/create`, {
      method: "POST",
      body: JSON.stringify(queryKey[1]),
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  ).json();
};

export interface RemoveStaffArgs {
  companyId: string;
  staffId: string;
}
const removeStaff = async ({ queryKey }) => {
  const authToken = localStorage.getItem("accessToken");
  return (
    await fetch(`${apiUrl}/company/staff/remove`, {
      method: "POST",
      body: JSON.stringify(queryKey[1]),
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  ).json();
};

// Verification Link endpoints -------------------------------------------------
export interface GenerateVerificationLinkArgs {
  documentTypes: string[];
  companyId: string;
}
const generateVerificationLink = async ({ queryKey }) => {
  const authToken = localStorage.getItem("accessToken");
  return (
    await fetch(`${apiUrl}/document-entries/create-self-verify-link`, {
      method: "POST",
      body: JSON.stringify(queryKey[1]),
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  ).json();
};

export interface GetVerificationLinkArgs {
  companyId: string;
}
const getVerificationLink = async ({ queryKey }) => {
  const authToken = localStorage.getItem("accessToken");
  return (
    await fetch(`${apiUrl}/document-entries/get-verify-links-data`, {
      method: "POST",
      body: JSON.stringify(queryKey[1]),
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  ).json();
};

export interface DisableVerificationLinkArgs {
  companyId: string;
  linkId: string;
}
const disableVerificationLink = async ({ queryKey }) => {
  const authToken = localStorage.getItem("accessToken");
  return (
    await fetch(`${apiUrl}/document-entries/disable-self-verify-link`, {
      method: "POST",
      body: JSON.stringify(queryKey[1]),
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  ).json();
};

// Company id is required to call this endpoint
interface GetCustomersArgs {
  companyId: string;
  searchValue: string;
  createdStartDate: number;
  createdEndDate: number;
  returnStartDate: number;
  returnEndDate: number;
}
const getCustomers = async ({ queryKey }) => {
  const authToken = localStorage.getItem("accessToken");
  return (
    await fetch(`${apiUrl}/company/customer/all`, {
      method: "POST",
      body: JSON.stringify(queryKey[1]),
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  ).json();
};

const convertToCustomer = async (body) => {
  const authToken = localStorage.getItem("accessToken");
  return (
    await fetch(`${apiUrl}/company/customer/convert`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  ).json();
};

const linkToCustomer = async (body) => {
  const authToken = localStorage.getItem("accessToken");
  return (
    await fetch(`${apiUrl}/company/customer/link`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  ).json();
};

const getPresignedUrl = async (body) => {
  const authToken = localStorage.getItem("accessToken");
  return (
    await fetch(`${apiUrl}/presigned-url/get`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  ).json();
};

const s3PutDocument = async ({ presignedUrl, file, type }) => {
  return await fetch(`${presignedUrl}`, {
    method: "PUT",
    body: file,
    headers: {
      "Content-Type": type,
    },
  });
};

const s3GetDocument = async ({ presignedUrl }) => {
  return (
    await fetch(`${presignedUrl}`, {
      method: "GET",
    })
  ).json();
};

// Wallet endpoint ------------------------
interface GetWalletTransactions {
  companyId: string;
}
const getWalletTransactions = async ({ queryKey }) => {
  const authToken = localStorage.getItem("accessToken");
  return (
    await fetch(`${apiUrl}/wallet/transactions/all`, {
      method: "POST",
      body: JSON.stringify(queryKey[1]),
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  ).json();
};

// Delete this
interface DoWalletRecharge {
  companyId: string;
  tokenCount: number;
}
const doWalletRecharge = async (body: DoWalletRecharge) => {
  const authToken = localStorage.getItem("accessToken");
  return (
    await fetch(`${apiUrl}/wallet/recharge/order`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  ).json();
};

const createOrder = async (body: DoWalletRecharge) => {
  const authToken = localStorage.getItem("accessToken");
  return (
    await fetch(`${apiUrl}/wallet/recharge/order`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  ).json();
};

interface GetWalletDetails {
  companyId: string;
}
const getWalletDetails = async ({ queryKey }) => {
  const authToken = localStorage.getItem("accessToken");
  return (
    await fetch(`${apiUrl}/wallet/details`, {
      method: "POST",
      body: JSON.stringify(queryKey[1]),
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  ).json();
};

// Reseller endpoints ---------------------------
const getCoupons = async () => {
  const authToken = localStorage.getItem("accessToken");
  return (
    await fetch(`${apiUrl}/reseller/available-coupons`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  ).json();
};

const requestCashPayment = async (body: any) => {
  const authToken = localStorage.getItem("accessToken");
  return (
    await fetch(`${apiUrl}/reseller/request-cash-payment`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  ).json();
};

const getResellerCoupon = async () => {
  const authToken = localStorage.getItem("accessToken");
  return (
    await fetch(`${apiUrl}/reseller/get-reseller-coupons`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  ).json();
};

interface CreateCoupon {
  couponPrefix: string;
}
const createCoupon = async (body: CreateCoupon) => {
  const authToken = localStorage.getItem("accessToken");
  return (
    await fetch(`${apiUrl}/reseller/generate-unique-coupon`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  ).json();
};

interface GetResellerSales {
  couponId: string;
  createdStartDate: number;
  createdEndDate: number;
}
const getResellerSales = async ({ queryKey }) => {
  const authToken = localStorage.getItem("accessToken");
  return (
    await fetch(`${apiUrl}/reseller/get-sales`, {
      method: "POST",
      body: JSON.stringify(queryKey[1]),
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  ).json();
};

const getResellerStats = async () => {
  const authToken = localStorage.getItem("accessToken");
  return (
    await fetch(`${apiUrl}/reseller/get-stats`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  ).json();
};

// Super admin endpoints

interface CreateReseller {
  phone: string;
  name: string;
  password: string;
}
const createReseller = async (body: any) => {
  const authToken = localStorage.getItem("accessToken");
  return (
    await fetch(`${apiUrl}/super-admin/create-reseller`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  ).json();
};

interface CreateCouponPrefix {
  couponPrefix: string;
  creditToken: number;
}
const createCouponPrefix = async (body: CreateCouponPrefix) => {
  const authToken = localStorage.getItem("accessToken");
  return (
    await fetch(`${apiUrl}/super-admin/create-coupon-prefix`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  ).json();
};

const getResellers = async ({ queryKey }) => {
  const authToken = localStorage.getItem("accessToken");
  return (
    await fetch(`${apiUrl}/super-admin/get-resellers`, {
      method: "POST",
      body: JSON.stringify(queryKey[1]),
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  ).json();
};

const resellerCoupons = async ({ queryKey }) => {
  const authToken = localStorage.getItem("accessToken");
  return (
    await fetch(`${apiUrl}/super-admin/reseller-coupons`, {
      method: "POST",
      body: JSON.stringify(queryKey[1]),
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  ).json();
};

const resellerStats = async ({ queryKey }) => {
  const authToken = localStorage.getItem("accessToken");
  return (
    await fetch(`${apiUrl}/super-admin/reseller-stats`, {
      method: "POST",
      body: JSON.stringify(queryKey[1]),
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  ).json();
};

const getAllTransactions = async ({ queryKey }) => {
  const authToken = localStorage.getItem("accessToken");
  return (
    await fetch(`${apiUrl}/super-admin/all-transactions`, {
      method: "POST",
      body: JSON.stringify(queryKey[1]),
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  ).json();
};

const getNextInvoiceSerial = async ({ queryKey }) => {
  const authToken = localStorage.getItem("accessToken");
  return (
    await fetch(`${apiUrl}/super-admin/get-next-invoice-serial `, {
      method: "POST",
      body: JSON.stringify(queryKey[1]),
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  ).json();
};

const getSkipInvoiceSerial = async () => {
  const authToken = localStorage.getItem("accessToken");
  return (
    await fetch(`${apiUrl}/super-admin/skip-invoice-serial`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  ).json();
};

const allCashPaymentRequests = async () => {
  const authToken = localStorage.getItem("accessToken");
  return (
    await fetch(`${apiUrl}/super-admin/cash-payment-requests`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  ).json();
};

const cashRequestApprove = async (body: any) => {
  const authToken = localStorage.getItem("accessToken");
  return (
    await fetch(`${apiUrl}/super-admin/approve-cash-payment`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  ).json();
};

const getAllCompanies = async (body: any) => {
  const authToken = localStorage.getItem("accessToken");
  return (
    await fetch(`${apiUrl}/super-admin/all-companies`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  ).json();
};

export {
  createCouponPrefix,
  getWalletDetails,
  doWalletRecharge,
  getWalletTransactions,
  createUser,
  loginUser,
  verifyUser,
  userDetails,
  createCompany,
  documentEntry,
  manualDocumentEntry,
  getDocumentEntries,
  getCustomers,
  getPresignedUrl,
  s3PutDocument,
  s3GetDocument,
  convertToCustomer,
  linkToCustomer,
  deleteCustomer,
  getStaffs,
  createStaff,
  removeStaff,
  generateVerificationLink,
  getVerificationLink,
  disableVerificationLink,
  getCompany,
  updateCompany,
  createOrder,
  updateCustomer,
  getCoupons,
  createCoupon,
  getResellerSales,
  getPublicLinkDetails,
  documentPublicEntry,
  getResellerCoupon,
  forgotPasswordRequest,
  forgotPasswordVerify,
  emailVerifyRequest,
  emailOtpVerify,
  resetPassword,
  getResellerStats,
  createReseller,
  getResellers,
  getAllTransactions,
  getNextInvoiceSerial,
  getSkipInvoiceSerial,
  requestCashPayment,
  allCashPaymentRequests,
  cashRequestApprove,
  resellerCoupons,
  resellerStats,
  getAllCompanies,
};
