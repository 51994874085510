import { Layout } from "components";
import React, { useState } from "react";
import ProfileDetails from "./ProfileDetails";
import CompanyDetails from "./CompanyDetails";
import { useMutation, useQuery } from "react-query";
import {
  getCompany,
  getWalletDetails,
  queryKeys,
  updateCompany,
  userDetails,
  userRoles,
} from "constant";
import { Calendar } from "react-date-range";
import toast, { Toaster } from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { createCompanyFromObject, createUserFromObject } from "pages/types";
import { ClockLoader } from "react-spinners";

// Demo picture asset
function ProfilePic() {
  return (
    <div className="proile-pic-box flex justify-center items-center">P</div>
  );
}

// Demo verify sign asset
function VerifySign() {
  return (
    <div className="verify-svg">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        className="mh-50px"
      >
        <path
          d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z"
          fill="currentColor"
        ></path>
        <path
          d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z"
          fill="white"
        ></path>
      </svg>
    </div>
  );
}

interface ProfileSummary {
  fullName: string;
  address: string;
  email: string;
  availableTokens: number;
  moneySpent: number;
}

// This is a temporary implementation of the company settings page the redirection to the different subsections should occur through page routing not by button clicking
function AccountSettingsPage() {
  const { state: companyMenuTabState } = useLocation();

  const profileMenu = "profile-menu";
  const settingsMenu = "settings-menu";
  const [activeMenu, setActiveMenu] = useState(
    companyMenuTabState ? companyMenuTabState : profileMenu
  );

  const {
    data: userData,
    isLoading: isUserDataLoading,
    refetch: refetchUserData,
  } = useQuery([queryKeys.USER_DETAILS], userDetails);
  const user = createUserFromObject(userData?.data);

  const { data: companyData, isLoading: isCompanyDataLoading } = useQuery(
    [
      queryKeys.GET_LINKED_DOCUMENTS,
      {
        companyId: userData?.data?.companyId,
      },
    ],
    getCompany,
    {
      enabled: !!userData?.data?.userId?.length,
    }
  );
  const company = createCompanyFromObject(companyData?.data?.Item);

  const { data: walletDetails, isLoading: isWalletDetailsLoading } = useQuery(
    [
      queryKeys.GET_WALLET_DETAILS,
      {
        companyId: userData?.data?.companyId,
      },
    ],
    getWalletDetails,
    {
      enabled: !!userData?.data?.userId?.length,
    }
  );
  const { tokens: availableTokens, totalMoneySpend } = walletDetails?.data
    ?.Item || {
    tokens: 0,
    totalMoneySpend: 0,
  };

  const { mutate: updateCompanyMutate, isLoading: isCompanyUpdating } =
    useMutation({
      mutationFn: updateCompany,
      onSuccess: (data) => {
        toast.success("profile updated successfully");
      },
    });

  return (
    <Layout>
      <div className="p-7 body-bg">
        <div className="veriication-detail-wrapper flex flex-col">
          <div className="document-type-box flex p-6 flex-col text-white proile-container">
            {/* Profile summary */}
            <div className="flex justify-between">
              <div className="flex gap-4">
                <ProfilePic />
                <div className="flex flex-col gap-2">
                  <div className="flex proile-name">
                    <span>{user.name}</span>
                    <VerifySign />
                  </div>
                  <div className="proile-options flex gap-3">
                    <span className="flex gap-1">
                      <i className="bi bi-person-circle"></i>
                      {user.name}
                    </span>
                    <span className="flex gap-1">
                      <i className="bi bi-geo-alt-fill"></i>
                      {"No user address"}
                    </span>

                    {user.email && (
                      <span className="flex gap-1">
                        <i className="bi bi-envelope"></i>
                        {user.email}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              {userData?.data?.role !== userRoles.SUPER_ADMIN &&
                userData?.data?.role !== userRoles.RESELLER && (
                  <div className="flex gap-2">
                    <CurrencyContainer>
                      <div className="avl-token-wrap flex flex-col justify-center items-center">
                        <span>{availableTokens}</span>
                        <h2 className="text-blue-500">Available tokens</h2>
                      </div>
                    </CurrencyContainer>
                    <CurrencyContainer>
                      <div className="avl-token-wrap total-spent-wrap flex flex-col justify-center items-center">
                        <span>{totalMoneySpend}</span>
                        <h2 className="text-emerald-400">Total spent</h2>
                      </div>
                    </CurrencyContainer>
                  </div>
                )}
            </div>
          </div>
        </div>
        {!isCompanyDataLoading && !isUserDataLoading && (
          <div className="flex flex-col justify-evenly text-white">
            <ProfileDetails
              companyProfileData={company}
              userProfileData={userData?.data}
              afterAnyServerAction={() => refetchUserData()}
            />
            {userData?.data?.role !== userRoles.SUPER_ADMIN &&
              userData?.data?.role !== userRoles.SUPER_ADMIN && (
                <CompanyDetails
                  profileData={company}
                  isUpdating={isCompanyUpdating}
                />
              )}
          </div>
        )}

        {isCompanyDataLoading || isUserDataLoading ? (
          <div className="h-full w-full flex justify-center items-center ">
            <ClockLoader color="white" />
          </div>
        ) : null}
      </div>

      <Toaster
        toastOptions={{
          success: {
            duration: 3000,
          },
        }}
      />
    </Layout>
  );
}

interface CurrencyContainerInterface {
  children: any;
}
function CurrencyContainer(props: CurrencyContainerInterface) {
  return <div className="hell">{props.children}</div>;
}

export { AccountSettingsPage };
