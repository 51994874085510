import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  doWalletRecharge,
  getWalletDetails,
  queryKeys,
  userDetails,
} from "constant";
import { ClockLoader, BarLoader } from "react-spinners";
import Modal from "react-responsive-modal";
import { useNavigate } from "react-router-dom";

interface TokenData {
  price: number;
  tokenNumber: number;
}
const leftTokens = 190;
const gstPercentage = 18;
const tokens: TokenData[] = [
  {
    price: 2000,
    tokenNumber: 50,
  },
  {
    price: 3500,
    tokenNumber: 100,
  },
  {
    price: 6000,
    tokenNumber: 200,
  },
];

function BuyWalletTokens() {
  const navigate = useNavigate();
  const [isEmailVerified, setIsEmailVerified] = useState(true);
  const { data: user, isLoading }: any = useQuery(
    [queryKeys.USER_DETAILS],
    userDetails
  );
  const {
    data: walletDetails,
    isLoading: isWalletDetailsLoading,
    refetch: refetchWalletDetails,
  } = useQuery(
    [
      queryKeys.GET_WALLET_DETAILS,
      {
        companyId: user?.data?.companyId,
      },
    ],
    getWalletDetails,
    {
      enabled: !!user?.data?.userId?.length,
    }
  );

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const { mutate: doWalletRechargeMutate } = useMutation({
    mutationFn: doWalletRecharge,
    onSuccess: async (data) => {
      // alert(JSON.stringify(data));
      await loadScript("https://checkout.razorpay.com/v1/checkout.js");

      const { id } = data.data;

      const options = {
        key: "rzp_test_IqJacSIdb38DiH",
        name: "SpaceShift",
        description: "Test Transaction",
        order_id: id,
        prefill: {
          name: "Tanmoy bhowmick",
          email: "gmtanmoy@gmail.com",
          contact: "8420216743",
        },
        handler: (response) => {
          refetchWalletDetails();
          // console.log("payment success razorpay", response);
        },
        theme: {
          color: "#3EBFB9",
        },
        modal: {},
      };

      const paymentObject = new (window as any).Razorpay(options);
      paymentObject.on("payment.failed", function (response) {
        alert(response.error.code);
      });
      paymentObject.open();
    },
  });

  console.log("user details", user);

  function handleTokenPurchase(tokenData: TokenData) {
    if (!user?.data?.isEmailVerified) {
      return setIsEmailVerified(false);
    }

    doWalletRechargeMutate({
      companyId: user?.data?.companyId,
      tokenCount: tokenData.tokenNumber,
    });
    console.log(
      `purchase ${tokenData.tokenNumber} tokens for Rs.${tokenData.price}`
    );
  }

  return (
    <div>
      <div className="filter-box flex flex-col p-7 buy-coupons-wrapper">
        <h2>Select from one of the packages below</h2>
        {isWalletDetailsLoading ? (
          <div
            style={{
              width: "100%",
              height: "30px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <BarLoader color="white" />{" "}
          </div>
        ) : (
          <span className="flex items-start p-1 self-start">
            Total Tokens left: {walletDetails?.data?.Item?.tokens}
          </span>
        )}
      </div>
      <div className="buy-tokens-wrapper p-7 flex">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
          {tokens.map((token, index) => (
            <TokenPurchaseCard
              price={token.price}
              tokenNumber={token.tokenNumber}
              gstPercentage={gstPercentage}
              onTokenPurchase={handleTokenPurchase}
              key={index}
            />
          ))}
        </div>
      </div>
      <Modal open={!isEmailVerified} 
       classNames={{
        overlay: 'customOverlay',
        modal: 'veriyEmailModal',
      }}
      onClose={() => setIsEmailVerified(true)}>
        <button
          className="blue-bt w-full"
          onClick={() => {
            navigate(`/account-settings?redirect=${window.location.pathname}`);
          }}
        >
          Verify email
        </button>
      </Modal>
    </div>
  );
}

interface TokenPurchaseCardProps {
  price: number;
  tokenNumber: number;
  gstPercentage: number;
  onTokenPurchase: (tokenData: TokenData) => any;
}
function TokenPurchaseCard(props: TokenPurchaseCardProps) {
  return (
    <div className="buy-token-box p-7 flex flex-col justify-center items-center">
      <div className="flex flex-col text-white justify-center items-center text-center">
        <h2 className="pb-2">₹{props.price}</h2>
        <h3>{props.tokenNumber} tokens</h3>
      </div>
      <p className="text-center pt-10 pb-5">
        Buy <b> {props.tokenNumber}</b> tokens for Rs.{props.price} +
        {props.gstPercentage}% GST {`(Excludes.)`}
      </p>
      <button
        className="blue-bt w-full"
        onClick={() =>
          props.onTokenPurchase({
            price: props.price,
            tokenNumber: props.tokenNumber,
          })
        }
      >
        Buy {props.tokenNumber} tokens
      </button>
    </div>
  );
}

export default BuyWalletTokens;
