import * as Yup from "yup";

const convertSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    phoneNumber: Yup.string()
        .matches(/^[0-9]\d{9}$/, { message: "Please enter valid phone number.", excludeEmptyString: false }),
    vehicleType: Yup.string().required("Vehicle type is required."),
    vehicleNumber: Yup.string().required("Vehicle number is required"),
    ratePerDay: Yup.number()
        .typeError('Please provide a valid rate per day amount.')
        .min(100, "rate amount is too little"),
    advancedPaid: Yup.string().matches(/^\d+$/, "Must be only digits"),
    returnDate: Yup.string().required("Return date is required")
});

const convertHotelBnbsSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    phoneNumber: Yup.string()
        .matches(/^[0-9]\d{9}$/, { message: "Please enter valid phone number.", excludeEmptyString: false }),
    roomType: Yup.string().required("Vehicle type is required."),
    roomNumber: Yup.string().required("Vehicle number is required"),
    dailyCost: Yup.number()
        .typeError('Please provide a valid rate per day amount.')
        .min(100, "rate amount is too little"),
    advancedPaid: Yup.number(),
    checkoutDate: Yup.string().required("Return date is required")
});

export { convertSchema, convertHotelBnbsSchema }