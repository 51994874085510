import { Layout } from "components";
import {
  documentEntry,
  documentTypeMap,
  queryKeys,
  userDetails,
  getWalletDetails,
  documentModeMap
} from "constant";
import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { MoonLoader, ClockLoader } from "react-spinners";
import toast, { Toaster } from "react-hot-toast";
import { DocumentDisplayCard } from "components/DocumentDisplayCard";
import moment from "moment";

function DLVerify() {
  const numRef: any = useRef();
  const dobRef: any = useRef();
  const [showData, setShowData] = useState<any>();
  const [searchParams] = useSearchParams();

  const { data: userData } = useQuery(
    [queryKeys.USER_DETAILS, { path: window.location.pathname }],
    userDetails
  );

  const { refetch: refetchWalletDetails } = useQuery(
    [
      queryKeys.GET_WALLET_DETAILS,
      {
        companyId: userData?.data?.companyId,
      },
    ],
    getWalletDetails,
    {
      enabled: !!userData?.data?.userId?.length,
    }
  );

  const { mutate: mutateDocument, isLoading } = useMutation({
    mutationFn: documentEntry,
    onSuccess: (data) => {
      if (
        (data?.data?.mapId === documentTypeMap.AADHAR ||
          data?.data?.mapId === documentTypeMap.DL ||
          data?.data?.mapId === documentTypeMap.PAN ||
          data?.data?.mapId === documentTypeMap.DIGI_EAADHAR) &&
        data?.data?.verified === "true"
      ) {
        toast.success("Document Verification Successful");
        setShowData(data);
      } else if (data?.data?.mapId === documentTypeMap.DIGI_URL) {
        localStorage.setItem("digiRequestId", data?.data?.requestId);
        window.location.replace(data?.data?.url);
      } else {
        toast.error("Third Party Server Error");
      }
      refetchWalletDetails();
    },
    onError: () => {
      alert("Third Party Server Error");
    },
  });

  useEffect(() => {
    if (
      searchParams.get("confirmAuthorization") == "true" &&
      userData?.data?.companyId
    ) {
      mutateDocument({
        documentId: localStorage.getItem("digiRequestId"), //  here document id is requestId
        companyId: userData?.data?.companyId,
        documentType: documentTypeMap.DL,
        mapId: documentTypeMap.DL,
      });
    }
  }, [userData]);

  return (
    <Layout>
      <div className="veriication-detail-wrapper flex flex-col p-7">
        <div className="document-type-box document-type-pan flex p-4">
          <h2>Document Type - Driving license</h2>
        </div>

        <div className="verify-box w-9/12 mt-7">
          <h2>Verify user with DL number</h2>

          <div className="adhaar-no-box">
            <h3>Enter Driving License Number</h3>
            <div className="adhaar-putin-box">
              <input className="border-2 w-full" ref={numRef}></input>
              <p className="mt-1 mb-2">
                Enter a valid number for Driving License verification.
              </p>
            </div>

            <h3>Choose DOB</h3>
            <div className="adhaar-putin-box">
              <input
                type="date"
                className="border-2 w-full"
                ref={dobRef}
              ></input>

              <button
                onClick={() => {
                  if (!dobRef?.current?.value || !numRef?.current?.value) {
                    return toast.error("All fields are mandatory");
                  }

                  mutateDocument({
                    documentId: numRef?.current?.value,
                    companyId: userData?.data?.companyId,
                    documentType: documentTypeMap.DL,
                    mapId: documentTypeMap.DL,
                    dob: moment(dobRef?.current?.value).format("DD/MM/YYYY"),
                  });
                }}
                className="blue-bt mt-5"
              >
                {isLoading ? (
                  <div>
                    <ClockLoader color="white" size="20" />
                  </div>
                ) : (
                  "Verify here"
                )}
              </button>
            </div>

            <div>After verification details are here</div>
            {showData ? (
              <DocumentDisplayCard
                doc={{
                  addedBy: userData?.data,
                  createdAt: Date.now(),
                  mode: documentModeMap[documentTypeMap.DL].mode,
                  meta: showData?.data,
                }}
              />
            ) : null}
          </div>
        </div>
      </div>

      <Toaster
        toastOptions={{
          success: {
            duration: 3000,
          },
        }}
      />
    </Layout>
  );
}

export { DLVerify };
