import { getCustomers, queryKeys, userDetails } from "constant";
import { useState } from "react";
import { useQuery } from "react-query";
import { ClockLoader } from "react-spinners";

function AssignCustomer({ doc, onAssign, onCancel, isLoading }) {
  const { data: userData } = useQuery(
    [queryKeys.USER_DETAILS, { path: window.location.pathname }],
    userDetails
  );
  const [linkCustomerId, setLinkCustomer] = useState("");

  const { data: customersData } = useQuery(
    [
      queryKeys.GET_CUSTOMERS,
      {
        companyId: userData?.data?.companyId,
      },
    ],
    getCustomers,
    { enabled: !!userData?.data?.userId?.length }
  );

  const customers = customersData?.data?.Items || [];

  return (
    <div className="modal-wrapper flex flex-col">
      <div className="modal-title flex p-6 w-full">
        Link document to customer
      </div>
      <div className="modal-body flex flex-col pt-0 p-6  w-full">
        <div className="assign-clients-wrap flex flex-col p-6">
        <h2 className="avail-clients-h2 mb-2">Select available Clients</h2>
          <div className="avail-clients">
            <select
              name=""
              id=""
              onChange={(e) => {
                setLinkCustomer(e.target.value);
              }}
            >
              <option value="">Select service Type</option>
              {customers?.map((customer) => (
                <option value={customer.customerId}>
                  {customer.meta.name}
                </option>
              ))}
            </select>
          </div>

          {customers.length == 0 && (
            <div className="no-active-client-found flex items-center justify-center mt-4">
              No Active Clients found!
            </div>
          )}
        </div>
      </div>

      <div className="modal-footer flex justify-end p-6 w-full gap-2">
        <button
          className="assign-bt blue-bt flex items-center"
          onClick={() => {
            onAssign(doc, linkCustomerId);
          }}
          disabled={isLoading}
        >
          {isLoading ? <ClockLoader color="white" size={20} /> : "Link Existing guest"}
        </button>
        <a className="cancel-bt flex items-center" onClick={onCancel}>
          Cancel
        </a>
      </div>
    </div>
  );
}

export { AssignCustomer };
