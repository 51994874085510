async function handleServerResponse(url: string, body: any) {
  const authToken = localStorage.getItem("accessToken");
  return fetch(url, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("Fetch Error: ", error);
    });
}

export { handleServerResponse };
