import { useEffect, useRef, useState } from "react";
import { DatePicker, Layout } from "components";
import {
  getDocumentEntries,
  documentTypeMap,
  userDetails,
  queryKeys,
  convertToCustomer,
  linkToCustomer,
  getCustomers,
  getWalletDetails,
} from "constant";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { RegisterClientData } from "pages/types";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { MdCancel } from "react-icons/md";
import { BsPatchCheckFill } from "react-icons/bs";
import toast, { Toaster } from "react-hot-toast";
import { ClockLoader, MoonLoader } from "react-spinners";
import ConvertCustomer from "./ConvertCustomer";

import { AssignCustomer } from "./AssignCustomer";
import { DocumentDisplayCard } from "components/DocumentDisplayCard";
import { ImFileEmpty } from "react-icons/im";

function DocumentsPage() {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const navigate = useNavigate();

  const onOpenModal = (doc: any) => {
    setViewDocument(doc);
    setOpen(true);
  };
  const onCloseModal = () => {
    setViewDocument(null);
    setOpen(false);
  };

  const onOpenModal2 = () => setOpen2(true);
  const onCloseModal2 = () => setOpen2(false);

  const onOpenModal3 = () => setOpen3(true);
  const onCloseModal3 = () => setOpen3(false);

  const { data: userData } = useQuery(
    [queryKeys.USER_DETAILS, { path: window.location.pathname }],
    userDetails
  );
  const [selectedType, setSelectedType] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [searchInputValue, setSearchInputValue] = useState("");
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(0);
  const [selectedDocument, setSelectedDocument] = useState<any>(null);
  const [viewDocument, setViewDocument] = useState<any>(null);
  const [LastEvaluatedKeys, setLastEvaluatedKey] = useState([]);

  const searchRef: any = useRef();

  const {
    data: documentsData,
    isLoading: isDocumentsDataLoading,
    refetch: refetchDocumentsData,
  } = useQuery(
    [
      queryKeys.DOCUMENT_ENTRIES,
      {
        companyId: userData?.data?.companyId,
        filterType: selectedType,
        searchValue: searchValue,
        startTime: startTime !== 0 ? (startTime * 1000).toString() : "",
        endTime: endTime !== 0 ? (endTime * 1000).toString() : "",
        limit: 100,
        LastEvaluatedKey: LastEvaluatedKeys?.length
          ? LastEvaluatedKeys[LastEvaluatedKeys?.length - 1]
          : "",
      },
    ],
    getDocumentEntries,
    {
      enabled: !!userData?.data?.userId?.length,
    }
  );

  const { refetch: customersDataRefetch } = useQuery(
    [
      queryKeys.GET_CUSTOMERS,
      {
        companyId: userData?.data?.companyId,
      },
    ],
    getCustomers,
    { enabled: !!userData?.data?.userId?.length }
  );
  const {
    data: walletDetailsData,
    isLoading: isWalletDetailsDataLoading,
    refetch: refetchWalletDetailsData,
  } = useQuery(
    [
      queryKeys.GET_WALLET_DETAILS,
      {
        companyId: userData?.data?.companyId,
      },
    ],

    getWalletDetails,
    {
      enabled: !!userData?.data?.userId?.length,
    }
  );

  const searchHandler = () => {
    setSearchValue(searchRef?.current?.value);
  };

  const {
    mutate: convertToCustomerMutate,
    isLoading: isConvertToCustomerMutating,
  } = useMutation({
    mutationFn: convertToCustomer,
    onSuccess: (data) => {
      customersDataRefetch();
      if (data.message.includes("nable")) {
        toast.error(data.message);
      } else {
        toast.success(data.message);
      }
      onCloseModal3();
      navigate("/customers", {
        replace: true,
      });
    },
    onError: () => {
      toast.error("Cannot convert customer");
    },
  });

  const { mutate: linkToCustomerMutate, isLoading: isLinkingToCustomer } =
    useMutation({
      mutationFn: linkToCustomer,
      onSuccess: (data) => {
        if (data.message.includes("nable")) {
          toast.error(data.message);
        } else {
          toast.success(data.message);
        }
        onCloseModal2();
        customersDataRefetch();
        refetchWalletDetailsData();
        navigate("/customers", {
          replace: true,
        });
      },
      onError: () => {
        toast.error("Cannot link customer");
      },
    });

  const convertToCustomerHandler = (
    registerData: RegisterClientData,
    document: any
  ) => {
    convertToCustomerMutate({
      companyId: userData?.data?.companyId,
      meta: registerData,
      documentEntryId: document.entryId,
      returnDate: registerData.checkoutDate * 1000,
      phone: registerData.phoneNumber,
    });
  };

  const linkToCustomerHandler = (linkedCustomerId: string, document: any) => {
    linkToCustomerMutate({
      documentEntryId: document.entryId,
      companyId: userData?.data?.companyId,
      customerId: linkedCustomerId,
    });
  };

  function handleSelectedTypeChange(val: string) {
    setSelectedType(val);
    console.log("Selected document type for filter", selectedType);
  }

  useEffect(() => {
    refetchDocumentsData();
  }, []);

  return (
    <Layout>
      <div className="p-7 body-bg">
        {/* Change the background color in the above div */}
        <div className="filter-box flex justify-between p-7">
          <div className="flex flex-col">
            <div className="filter-title">
              <h2>Filters</h2>
            </div>
            <div className="flex items-center mt-2 gap-3">
              <div className="choose-doc-type">
                <select
                  name=""
                  id=""
                  onChange={(e) => handleSelectedTypeChange(e.target.value)}
                >
                  <option value="">Choose Doc Type</option>
                  <option value={documentTypeMap.AADHAR}>Aadhaar</option>
                  <option value={documentTypeMap.DL}>Driving License</option>
                  <option value={documentTypeMap.PAN}>PAN</option>
                </select>
              </div>
              <div className="search-by-id flex items-center gap-2">
                <div className="flex items-center search-input-box choose-doc-type">
                  <i className="bi bi-search"></i>
                  <input
                    type="text"
                    ref={searchRef}
                    onChange={(e) => {
                      setSearchInputValue(e.currentTarget.value);
                    }}
                    value={searchInputValue}
                    placeholder="Search with last 4 digits"
                  />
                </div>
                <button className="serach-bt" onClick={() => searchHandler()}>
                  {" "}
                  <i className="bi bi-search"></i>
                </button>
                <button
                  className="refresh-bt"
                  onClick={() => {
                    setSearchValue("");
                    setSearchInputValue("");
                  }}
                >
                  <i className="bi bi-arrow-clockwise"></i>
                </button>
              </div>
            </div>
          </div>

          <div className="flex flex-col filter-date">
            <h2 className="mb-2">Filter By Date: </h2>
            <DatePicker
              onChange={(dateRange) => {
                setStartTime(dateRange.startDate);
                setEndTime(dateRange.endDate);
              }}
            />
          </div>
        </div>

        <div className="all-verified-documents-wrap flex p-7 mt-8 flex-col">
          <div className="flex justify-between items-center mb-6">
            <h2 className="">All Verified Documents</h2>
            <div className="flex items-center gap-2">
              {selectedDocument && !selectedDocument.isCustomer && (
                <>
                  <a
                    className="assign-bt flex items-center font-bold"
                    onClick={onOpenModal2}
                  >
                    Link to guest
                  </a>
                  <a
                    className="convert-bt flex items-center font-bold"
                    onClick={onOpenModal3}
                  >
                    {" "}
                    Register new guest
                  </a>
                </>
              )}
              {selectedDocument && selectedDocument.isCustomer && (
                <a href="" className="already-client-bt flex items-center">
                  Already a Client
                </a>
              )}
            </div>
          </div>

          {/* Table */}

          <div
            className="table-container w-full"
            style={{ height: "56vh", overflow: "auto" }}
          >
            <table className="w-full">
              <tbody>
                <tr className="head-bg">
                  <th>#</th>
                  <th>Document Type</th>
                  <th>Doc ID</th>
                  <th>Status</th>
                  <th>Created Date</th>
                  <th>Mode</th>
                  <th>Verified By</th>
                  <th>Action</th>
                </tr>
                {documentsData?.data?.Items?.length &&
                  documentsData?.data?.Items?.map((documentMember) => {
                    return (
                      <tr
                        onClick={() => setSelectedDocument(documentMember)}
                        style={{ cursor: "pointer" }}
                      >
                        <td>
                          <input
                            type="radio"
                            name="doc-rad"
                            onChange={() => setSelectedDocument(documentMember)}
                            checked={selectedDocument === documentMember}
                          />
                        </td>
                        <td>{documentMember.documentType}</td>
                        <td>{`********${documentMember.documentId.slice(
                          -4
                        )}`}</td>
                        <td>
                          <div
                            className="flex flex-row"
                            style={{
                              gap: "0.4rem",
                            }}
                          >
                            <div
                              className="flex flex-row items-center"
                              style={{
                                color: "green",
                                gap: "0.1rem",
                              }}
                            >
                              {documentMember.isByOtp && (
                                <>
                                  <BsPatchCheckFill />
                                  <span>verified by otp</span>
                                </>
                              )}
                              {!documentMember.isByOtp && (
                                <>
                                  <BsPatchCheckFill />
                                  <span>verified</span>
                                </>
                              )}
                            </div>
                            <div
                              className="flex flex-row items-center"
                              style={{
                                color: documentMember.isCustomer
                                  ? "green"
                                  : "red",
                                gap: "0.1rem",
                              }}
                            >
                              {documentMember.isCustomer ? (
                                <BsPatchCheckFill />
                              ) : (
                                <MdCancel />
                              )}
                              <span>client</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          {moment(documentMember.createdAt).format(
                            "DD-MM-YYYY"
                          )}
                        </td>
                        <td>{documentMember.mode}</td>
                        <td>{documentMember.addedBy.name}</td>
                        <td>
                          <a
                            onClick={(e) => {
                              onOpenModal(documentMember);
                            }}
                            className="view flex items-center justify-center"
                          >
                            View
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                {documentsData?.data?.Items?.length == 0 && (
                  <tr>
                    <td colSpan={10}>
                      <div className="justify-center items-center flex-col flex my-5 h-[35vh]">
                        <ImFileEmpty className="text-5xl mb-4" />
                        <div className=" font-bold text-xl">
                          No records found
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
                {isDocumentsDataLoading && (
                  <tr>
                    <td colSpan={7}>
                      <div
                        style={{
                          width: "100%",
                          height: "300px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <ClockLoader color="white" size="30" />{" "}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex back-next-bt-wrapper justify-end gap-3 items-end mt-4">
          <div
            className="flex items-center"
            onClick={() => {
              setLastEvaluatedKey((olds: any) => {
                const newArr = JSON.parse(JSON.stringify(olds));
                return newArr?.length ? newArr?.slice(0, -1) : newArr;
              });
            }}
            style={{
              backgroundColor: LastEvaluatedKeys?.length
                ? "#009ef7"
                : "#2b2b40",
              color: LastEvaluatedKeys?.length ? "white" : "#7e8299",
            }}
          >
            Back
          </div>
          <div
            onClick={() => {
              if (documentsData?.data?.LastEvaluatedKey) {
                setLastEvaluatedKey((olds) => {
                  return [...olds, documentsData?.data?.LastEvaluatedKey];
                });
              }
            }}
            className={`flex items-center`}
            style={{
              backgroundColor: documentsData?.data?.LastEvaluatedKey
                ? "#009ef7"
                : "#2b2b40",
              color: documentsData?.data?.LastEvaluatedKey
                ? "white"
                : "#7e8299",
            }}
          >
            Next
          </div>
        </div>
      </div>

      {/* Details view modal */}
      <Modal open={open} onClose={onCloseModal} center>
        {viewDocument && <DocumentDisplayCard doc={viewDocument} />}
      </Modal>

      {/* assign modal */}
      <Modal open={open2} onClose={onCloseModal2} center>
        <AssignCustomer
          isLoading={isLinkingToCustomer}
          doc={selectedDocument}
          onAssign={(document, linkCustomerId) =>
            linkToCustomerHandler(linkCustomerId, document)
          }
          onCancel={onCloseModal2}
        />
      </Modal>

      {/* convert modal */}
      <Modal open={open3} onClose={onCloseModal3} center>
        <ConvertCustomer
          isConverting={isConvertToCustomerMutating}
          onCancel={onCloseModal3}
          onConvert={(registrationData) => {
            convertToCustomerHandler(
              {
                advancedPaid: registrationData.advancedPaid,
                checkoutDate: registrationData.checkoutDate,
                dailyCost: registrationData.dailyCost,
                name: registrationData.name,
                noOfDays: registrationData.noOfDays,
                phoneNumber: registrationData.phoneNumber,
                roomNumber: registrationData.roomNumber,
                roomType: registrationData.roomType,
              },
              selectedDocument
            );
          }}
          doc={selectedDocument}
        />
      </Modal>

      <Toaster
        toastOptions={{
          success: {
            duration: 3000,
          },
        }}
      />
    </Layout>
  );
}

export { DocumentsPage };
