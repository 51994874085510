import { Layout } from "components";
import { useQuery, useMutation } from "react-query";
import { allCashPaymentRequests, cashRequestApprove } from "constant";
import toast, { Toaster } from "react-hot-toast";

function CashPaymentRequests() {
  const { data } = useQuery(["cash-payment-requests"], allCashPaymentRequests);
  console.log("data", data);
  const { mutate: cashApprove } = useMutation({
    mutationFn: cashRequestApprove,
    onSuccess: async (data) => {
      toast.success("Payment approved");
    },
  });

  return (
    <Layout>
      <div className="text-white">
        <div className="p-7 body-bg">
          <div className="all-verified-documents-wrap flex p-7 flex-col">
            <div>
              <div className="flex justify-between items-center mb-6">
                <h2>Gift requests</h2>
              </div>
              <div className="table-container w-full">
                <table className="w-full">
                  <thead>
                    <tr className="head-dashed-underline">
                      <th className="uppercase text-left">Request for</th>
                      <th className="uppercase">Request for Phone</th>
                      <th className="uppercase">Request token</th>
                      <th className="uppercase">Reseller id</th>
                      <th className="uppercase">Request amount</th>
                      <th className="uppercase">Approved</th>
                      <th className="uppercase"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.data?.Items?.map((item) => {
                      return (
                        <tr>
                          <td className="text-left">
                            {item.requestedForUserId}
                          </td>
                          <td className="">{item.requestedTokens}</td>
                          <td className=""> {item.resellerId}</td>
                          <td className=""> {item.requestedAmount}</td>
                          <td className=""> {item.requestedAmount}</td>
                          <td className="">false</td>
                          <td className="">
                            <button
                              className="p-2 bg-green-500 text-white rounded-md"
                              onClick={() => {
                                cashApprove({ requestId: item.id });
                              }}
                            >
                              Approve
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* Request for: {item.requestedForUserId}, Request token:{" "}
              {item.requestedTokens}, Reseller id: {item.resellerId} , Request
              amount: {item.requestedAmount}, Approved:{" "}
              {String(item.isApproved)}{" "} */}
        </div>
      </div>
      <Toaster
        toastOptions={{
          success: {
            duration: 3000,
          },
        }}
      />
    </Layout>
  );
}

export { CashPaymentRequests };
