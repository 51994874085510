import {
  getWalletDetails,
  getWalletTransactions,
  queryKeys,
  userDetails,
} from "constant";
import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import ReactDOM from "react-dom";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { ClockLoader } from "react-spinners";
import { DatePicker } from "components";
import moment from "moment";
import { ImFileEmpty } from "react-icons/im";

function Billing({
  onInvoiceDownload,
  isInvoiceDownloadLoading,
  ...props
}: {
  onInvoiceDownload: (item: any) => any;
  isInvoiceDownloadLoading: boolean;
}) {
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(0);

  const { data: user, isLoading }: any = useQuery(
    [queryKeys.USER_DETAILS],
    userDetails
  );

  const {
    data: walletTransactionsData,
    isLoading: isWalletTransactionsLoading,
  } = useQuery(
    [
      queryKeys.GET_TRANSACTIONS,
      {
        companyId: user?.data?.companyId,
        startTime: startTime !== 0 ? (startTime * 1000).toString() : "",
        endTime: endTime !== 0 ? (endTime * 1000).toString() : "",
        isOnlyInvoice: true,
      },
    ],
    getWalletTransactions,
    {
      enabled: !!user?.data?.userId?.length,
    }
  );

  return (
    <div>
      <div className="all-verified-documents-wrap flex p-7 mt-8 flex-col">
        <div className="flex justify-between items-center mb-6">
          <h2 className="">Invoices</h2>
          <div className="flex items-center gap-2">
            {" "}
            <div className="flex flex-col filter-date mb-3">
              <h3 className="mb-2 text-sm font-semibold text-white">
                Filter By Date:{" "}
              </h3>
              <DatePicker
                onChange={(dateRange) => {
                  setStartTime(dateRange.startDate);
                  setEndTime(dateRange.endDate);
                  console.log(dateRange);
                }}
              />
            </div>
          </div>
        </div>

        <div
          className="table-container w-full"
          style={{ maxHeight: "60vh", overflow: "auto" }}
        >
          <table className="w-full">
            <tbody>
              <tr className="head-bg">
                <th>Transaction Id</th>
                <th>Token Credit</th>
                <th>Money spend</th>
                <th>Timestamp</th>
                <th>Updated tokens</th>
                <th>Email Sent</th>
                <th>Invoice</th>
              </tr>
              {walletTransactionsData?.data?.Items?.map((item) => {
                const {
                  createdAt,
                  currentTokens,
                  moneySpend,
                  tokenCredit,
                  transactionId,
                  isEmailSent,
                } = item;

                return (
                  <tr>
                    <td>{transactionId}</td>
                    <td>{tokenCredit}</td>
                    <td>{moneySpend}</td>
                    <td>{moment(createdAt).format("DD-MM-YYYY")}</td>
                    <td>{currentTokens}</td>
                    <td>{isEmailSent ? "Yes" : "No"}</td>
                    <td>
                      {moneySpend ? (
                        <button
                          onClick={() => onInvoiceDownload(item)}
                          disabled={isInvoiceDownloadLoading}
                          className="rounded px-3 py-2 bg-green-600 font-bold"
                        >
                          Download
                        </button>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>

            {walletTransactionsData?.data?.Items?.length == 0 && (
              <tr>
                <td colSpan={7}>
                  <div className="justify-center items-center flex-col flex">
                    <ImFileEmpty className="text-5xl mb-4" />
                    <div className=" font-bold text-xl">No records found</div>
                  </div>
                </td>
              </tr>
            )}
            {isWalletTransactionsLoading && (
              <tr>
                <td colSpan={7}>
                  <div
                    style={{
                      width: "100%",
                      height: "300px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <ClockLoader color="white" size="20" />{" "}
                  </div>
                </td>
              </tr>
            )}
          </table>
          di
        </div>
      </div>
    </div>
  );
}

interface CurrencyContainerInterface {
  children: any;
}
function CurrencyContainer(props: CurrencyContainerInterface) {
  return (
    <div className="avl-token-wrap flex flex-col justify-center items-center">
      {props.children}
    </div>
  );
}

export default Billing;
