import { useState, useEffect } from "react";
import * as Form from "@radix-ui/react-form";
import { MoonLoader } from "react-spinners";

interface VerifyOtpProps {
  submitHandler: (otp: number) => any;
  isLoading: boolean;
  resendOtpHandler: () => any;
  otpExpireMinutes: number;
  otpExpireSeconds?: number;
  onTimerEnd?: () => any;
}
function VerifyOtp({
  submitHandler,
  isLoading,
  resendOtpHandler,
  otpExpireMinutes,
  otpExpireSeconds,
  onTimerEnd,
}: VerifyOtpProps) {
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [showTimer, setShowTimer] = useState(false);

  useEffect(() => {
    setMinutes(otpExpireMinutes < 0 ? 0 : otpExpireMinutes);
    setSeconds(otpExpireSeconds < 0 ? 0 : otpExpireSeconds);
  }, [otpExpireMinutes, otpExpireSeconds]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
          onTimerEnd?.();
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    if (!minutes && !seconds) {
      setShowTimer(false);
    } else {
      setShowTimer(true);
    }

    return () => {
      clearInterval(interval);
    };
  }, [otpExpireMinutes, minutes, seconds]);

  return (
    <>
      <Form.Root
        className=""
        onSubmit={(event: any) => {
          event.preventDefault();
          submitHandler(event.target.elements.otp.value);
        }}
      >
        <Form.Field className="grid mb-[10px]" name="otp">
          <div className="flex items-baseline justify-between">
            <Form.Label className="text-[15px] font-medium leading-[35px] text-white">
              OTP
            </Form.Label>

            <Form.Message
              className="text-[13px] text-black opacity-[0.8]"
              match={(value, formData) => value.length !== 4}
            >
              otp length is not valid
            </Form.Message>
          </div>
          <Form.Control asChild>
            <input
              className="text-black box-border w-full bg-blackA2 shadow-blackA6 inline-flex h-[35px] appearance-none items-center justify-center rounded-[4px] px-[10px] text-[15px] leading-none text-black shadow-[0_0_0_1px] outline-none hover:shadow-[0_0_0_1px_black] focus:shadow-[0_0_0_2px_black] selection:color-white selection:bg-blackA6"
              type="number"
              required
            />
          </Form.Control>
        </Form.Field>
        {showTimer && (
          <div>
            Otp will expire in {minutes} minutes and {seconds} seconds
          </div>
        )}

        <Form.Submit asChild>
          <div className="flex items-center justify-center">
          <button className="blue-bt mt-3">
            {isLoading ? (
              <MoonLoader color="rgba(12, 15, 55, 1)" size="20" />
            ) : (
              <div>Submit</div>
            )}
          </button>
          </div>
        </Form.Submit>
      </Form.Root>
      <div className="flex self-center justify-center items-center">
        <button
          onClick={() => {
            setMinutes(otpExpireMinutes);
            setSeconds(0);
            resendOtpHandler();
          }}
          className="p-2 ps-4 pr-4 rounded-lg text-blue-300 mt-3"
          disabled={!(minutes === 0 && seconds === 0)}
        >
          Resend OTP
        </button>
      </div>
    </>
  );
}

export { VerifyOtp };
