import { Layout } from "components";
import { useQuery } from "react-query";
import {
  documentTypeMap,
  getDocumentEntries,
  getWalletDetails,
  queryKeys,
  userDetails,
  userRoles,
} from "constant";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { RiAiGenerate } from "react-icons/ri";
import Modal from "react-responsive-modal";

function HomePage() {
  const [showBuyTokens, setShowBuyTokens] = useState(false);

  const { data, isLoading }: any = useQuery(
    [queryKeys.USER_DETAILS],
    userDetails
  );
  const {
    data: walletDetails,
    isLoading: isWalletDetailsLoading,
    refetch: refetchWalletDetails,
  } = useQuery(
    [
      queryKeys.GET_WALLET_DETAILS,
      {
        companyId: data?.data?.companyId,
      },
    ],
    getWalletDetails,
    {
      enabled: !!data?.data?.userId?.length,
    }
  );

  const { data: adhaarDocumentsData, isLoading: isAdhaarDocumentsDataLoading } =
    useQuery(
      [
        queryKeys.DOCUMENT_ENTRIES,
        {
          companyId: data?.data?.companyId,
          filterType: documentTypeMap.AADHAR,
        },
      ],
      getDocumentEntries,
      { enabled: !!data?.data?.userId?.length }
    );

  const { data: panDocumentsData, isLoading: isPanDocumentsDataLoading } =
    useQuery(
      [
        queryKeys.DOCUMENT_ENTRIES,
        {
          companyId: data?.data?.companyId,
          filterType: documentTypeMap.PAN,
        },
      ],
      getDocumentEntries,
      {
        enabled: !!data?.data?.userId?.length,
      }
    );

  const {
    data: drivingLicenseDocumentsData,
    isLoading: isDrivingLicenseDocumentsDataLoading,
  } = useQuery(
    [
      queryKeys.DOCUMENT_ENTRIES,
      {
        companyId: data?.data?.companyId,
        filterType: documentTypeMap.DL,
      },
    ],
    getDocumentEntries,
    {
      enabled: !!data?.data?.userId?.length,
    }
  );

  const { data: manualDocumentsData, isLoading: isManualDocumentsDataLoading } =
    useQuery(
      [
        queryKeys.DOCUMENT_ENTRIES,
        {
          companyId: data?.data?.companyId,
          filterType: documentTypeMap.MANUAL,
        },
      ],
      getDocumentEntries,
      {
        enabled: !!data?.data?.userId?.length,
      }
    );

  const navigate = useNavigate();

  const role = data?.data?.role;
  // const role = userRoles.SUPER_ADMIN;

  return (
    <Layout>
      <div className="verify-doc-wrapper grid grid-cols-1 md:grid-cols-3 gap-4">
        <div
          className="p-6 verify-doc-box adhaar-box flex justify-between items-center"
          onClick={() => {
            if (walletDetails?.data?.Item?.tokens < 1) {
              return setShowBuyTokens(true);
            }

            navigate("/verify/aadhar");
          }}
        >
          <div className="inner-info flex flex-col gap-2">
            <img src="/images/aadhar.png" alt="" />
            <h2> Aadhaar Verification</h2>
          </div>

          {!isAdhaarDocumentsDataLoading && (
            <div className="inner-info flex flex-col gap-2">
              <h3>{adhaarDocumentsData?.data?.Items?.length}</h3>
              <h2>Total Verified</h2>
            </div>
          )}
        </div>

        <div
          className="p-6 verify-doc-box pan-box flex justify-between items-center"
          onClick={() => {
            if (walletDetails?.data?.Item?.tokens < 1) {
              return setShowBuyTokens(true);
            }
            navigate("/verify/pan");
          }}
        >
          <div className="inner-info flex flex-col gap-2">
            <img src="/images/pan.png" alt="" />
            <h2> PAN Verification</h2>
          </div>

          {!isPanDocumentsDataLoading && (
            <div className="inner-info flex flex-col gap-2">
              <h3>{panDocumentsData?.data?.Items?.length}</h3>
              <h2>Total Verified</h2>
            </div>
          )}
        </div>

        <div
          className="p-6 verify-doc-box dl-box flex justify-between items-center"
          onClick={() => {
            if (walletDetails?.data?.Item?.tokens < 1) {
              return setShowBuyTokens(true);
            }
            navigate("/verify/dl");
          }}
        >
          <div className="inner-info flex flex-col gap-2">
            <img src="/images/driving-license.png" alt="" />
            <h2> Driving License Verification</h2>
          </div>

          {!isDrivingLicenseDocumentsDataLoading && (
            <div className="inner-info flex flex-col gap-2">
              <h3>{drivingLicenseDocumentsData?.data?.Items?.length}</h3>
              <h2>Total Verified</h2>
            </div>
          )}
        </div>
        <div
          className="p-6 verify-doc-box voter-box flex justify-between items-center"
          onClick={() => {
            if (walletDetails?.data?.Item?.tokens < 1) {
              return setShowBuyTokens(true);
            }
            navigate("/verify/manual");
          }}
        >
          <div className="inner-info flex flex-col gap-2">
            <img src="/images/voter.png" alt="" />
            <h2> Manual Verification</h2>
          </div>

          {!isManualDocumentsDataLoading && (
            <div className="inner-info flex flex-col gap-2">
              <h3>{manualDocumentsData?.data?.Items?.length}</h3>
              <h2>Total Verified</h2>
            </div>
          )}
        </div>

        <div
          className="p-6 verify-doc-box voter-box flex justify-between items-center"
          onClick={() => {
            navigate("/verification-link");
          }}
        >
          <div className="inner-info flex flex-col gap-2">
            <RiAiGenerate className="text-5xl text-yellow-600"/>
            <h2> Generate self verification link</h2>
          </div>
        </div>
      </div>
    
      <Modal open={showBuyTokens} 
      classNames={{
        overlay: 'customOverlay',
        modal: 'notenoughTokenModal',
      }}
      onClose={() => setShowBuyTokens(false)}>

        <div className="mod-body">
        <h1 className="text-white p-6 text-center">
          Not enough tokens to continue with document verification
        </h1>
        <div className="p-6 pt-0">
        <button
          className="blue-bt w-full"
          onClick={() => navigate("/wallet", { state: "buy-tokens" })}
        >
          Buy tokens
        </button>
        </div>
        </div>
      </Modal>
     
    </Layout>
  );
}

export { HomePage };
