import { DatePicker, Layout } from "components";
import {
  disableVerificationLink,
  documentTypeMap,
  generateVerificationLink,
  getVerificationLink,
  getWalletDetails,
  queryKeys,
  selfLinkVerifyStatus,
  userDetails,
} from "constant";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useMutation, useQuery } from "react-query";
import LinkDetails from "./LinkDetails";
import { ClockLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import Modal from "react-responsive-modal";
import ConfirmationModal from "components/ConfirmationModal";
import { DateRangeUnix, createWalletFromObject } from "pages/types";
import { BuyTokens } from "components/BuyTokens";
import { ImFileEmpty } from "react-icons/im";

const selectionOptions = {
  reset: "RESET",
  statusType: "STATUS_TYPE",
  createdDate: "CREATED_DATE",
};

interface LinkListItem {
  serialId: number;
  linkCode: string;
  documents: string[];
  status: string;
  createdDate: number;
  expired: boolean;
}
const dummyLinkListItems: LinkListItem[] = [
  {
    serialId: 1,
    createdDate: 1701454160,
    documents: [documentTypeMap.AADHAR, documentTypeMap.DL],
    expired: false,
    linkCode: "pNp-OczyI3mrYi6jiW19l",
    status: "Rollback",
  },
  {
    serialId: 2,
    createdDate: 1701454347,
    documents: [documentTypeMap.AADHAR, documentTypeMap.DL],
    expired: true,
    linkCode: "pNp-OczyI3mrYi6jiW19l",
    status: "Rollback",
  },
];

function VerificationLinkPage() {
  const [documentTypes, setDocumentTypes] = useState<string[]>([]);
  const [selectedDocumentType, setSelectedDocumentType] = useState<{
    adhaar: boolean;
    drivingLicense: boolean;
  }>({
    adhaar: false,
    drivingLicense: false,
  });
  const [viewLink, setViewLink] = useState(false);
  const [viewLinkData, setViewLinkData] = useState(null);
  const [confirmationModalPayload, setConfirmationModalPayload] =
    useState(null);
  const [confirmationModalView, setConfirmationModalView] = useState(false);
  const [createdDate, setCreatedDate] = useState<{
    startDate: number;
    endDate: number;
  }>({
    startDate: 0,
    endDate: 0,
  });
  const [selectedStatusType, setSelectedStatusType] = useState("");
  const [showBuyTokens, setShowBuyTokens] = useState(false);
  const [LastEvaluatedKeys, setLastEvaluatedKey] = useState([]);

  console.log(documentTypes);
  const navigate = useNavigate();

  const { data: user, isLoading: isUserLoading }: any = useQuery(
    [queryKeys.USER_DETAILS],
    userDetails
  );
  const {
    data: walletDetails,
    isLoading: isWalletDetailsLoading,
    refetch: walletDetailsRefetch,
  } = useQuery(
    [
      queryKeys.GET_WALLET_DETAILS,
      {
        companyId: user?.data?.companyId,
      },
    ],
    getWalletDetails,
    {
      enabled: !!user?.data?.userId?.length,
    }
  );

  const wallet = createWalletFromObject(walletDetails?.data?.Item);

  const {
    mutate: mutateDisableVerificationLink,
    isLoading: isDisablingVerificationLink,
  } = useMutation({
    mutationFn: disableVerificationLink,
    onSuccess: (data) => {
      verificationLinksDataRefetch();
      toast.success(data.message);
    },
    onError: (error) => {
      toast.error("Failed to disable verification link");
    },
  });

  const {
    mutate: mutateGenerateVerificationLink,
    isLoading: isGeneratingVerificationLink,
  } = useMutation({
    mutationFn: generateVerificationLink,
    onSuccess: (data) => {
      verificationLinksDataRefetch();
      walletDetailsRefetch();
      setDocumentTypes([]);
      toast.success(data.message);
    },
    onError: (error) => {
      toast.error(JSON.stringify(error));
    },
  });

  const {
    data: verificationLinkData,
    isLoading: isVerificationLinkLoading,
    refetch: verificationLinksDataRefetch,
  } = useQuery(
    [
      queryKeys.GET_VERIFICATION_LINKS,
      {
        companyId: user?.data?.companyId,
        status: selectedStatusType,
        startTime:
          createdDate.startDate == 0
            ? ""
            : (createdDate.startDate * 1000).toString(),
        endTime:
          createdDate.endDate == 0
            ? ""
            : (createdDate.endDate * 1000).toString(),
        limit: 100,
        LastEvaluatedKey: LastEvaluatedKeys?.length
          ? LastEvaluatedKeys[LastEvaluatedKeys?.length - 1]
          : "",
      },
    ],
    getVerificationLink,
    {
      enabled: !!user?.data?.userId?.length,
    }
  );

  console.log("verification links data", verificationLinkData);

  function handleDocumentTypeDeSelection(typeOfDocument: string) {
    setDocumentTypes((documentTypes) => {
      const newDocumentTypes = documentTypes.filter(
        (item) => item !== typeOfDocument
      );
      return newDocumentTypes;
    });
  }

  function handleLinkGeneration() {
    if (documentTypes.length == 0) {
      toast.error("Please select a document type to continue");

      return;
    }

    if (wallet.tokens < documentTypes.length) {
      return setShowBuyTokens(true);
    }

    mutateGenerateVerificationLink({
      queryKey: [
        "",
        {
          companyId: user?.data?.companyId,
          documentTypes: documentTypes,
        },
      ],
    });
    setDocumentTypes([]);
    setSelectedDocumentType({
      adhaar: false,
      drivingLicense: false,
    });
  }

  function handleViewLink(link) {
    setViewLinkData(link);
    setViewLink(true);
  }

  function handleDisableLink(linkId: string) {
    mutateDisableVerificationLink({
      queryKey: [
        "",
        {
          companyId: user?.data?.companyId,
          linkId: linkId,
        },
      ],
    });
  }

  async function handleCopyLink(text) {
    try {
      await navigator.clipboard.writeText(text);
      toast.success("link copied to clipboard");
    } catch (error) {
      toast.error("error in copying the link");
    }
  }

  function handleStatusTypeChange(statusType: string) {
    console.log(statusType);
    setSelectedStatusType(statusType);
  }

  useEffect(() => {
    const newDocumentTypes: string[] = [];

    if (selectedDocumentType.adhaar) {
      newDocumentTypes.push(documentTypeMap.AADHAR);
    }
    if (selectedDocumentType.drivingLicense) {
      newDocumentTypes.push(documentTypeMap.DL);
    }

    setDocumentTypes(newDocumentTypes);
  }, [selectedDocumentType]);

  return (
    <Layout>
      <div className="p-7 body-bg">
        {/* Change the background color in the above div */}
        <div className="filter-box flex justify-between p-7">
          <div className="flex flex-col">
            <div className="filter-title">
              <h2>
                Select the document type that you want your client to verify
              </h2>
            </div>
            <div className="p-3 flex items-start mt-2 gap-3">
              <div>
                <div className="flex flex-row my-2" style={{ gap: "3rem" }}>
                  <div className="flex flex-row gap-2  items-center">
                    <input
                      checked={selectedDocumentType.adhaar}
                      type="checkbox"
                      onChange={(e) => {
                        setSelectedDocumentType((prev) => {
                          return {
                            ...prev,
                            adhaar: !documentTypes.includes(
                              documentTypeMap.AADHAR
                            ),
                          };
                        });
                      }}
                    />
                    <span style={{ color: "white" }}>Adhaar</span>
                  </div>

                  <div className="flex flex-row gap-2  items-center">
                    <input
                      checked={selectedDocumentType.drivingLicense}
                      type="checkbox"
                      onChange={(e) => {
                        setSelectedDocumentType((prev) => {
                          return {
                            ...prev,
                            drivingLicense: !documentTypes.includes(
                              documentTypeMap.DL
                            ),
                          };
                        });
                      }}
                    />
                    <span style={{ color: "white" }}>Driving license</span>
                  </div>
                </div>
                <button
                  className="convert-bt flex items-center font-bold mt-5"
                  onClick={handleLinkGeneration}
                  disabled={isGeneratingVerificationLink}
                >
                  {isGeneratingVerificationLink ? (
                    <ClockLoader size={20} color="white" />
                  ) : (
                    "Generate Link"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="all-verified-documents-wrap flex p-7 mt-8 flex-col">
          <div className="flex flex-col gap-4">
            <h3 className="text-white font-bold">Your generated links</h3>

            <div className="flex items-center gap-3">
              {/* <button>Reset</button> */}

              <DatePicker
                onChange={(data) =>
                  setCreatedDate({
                    startDate: data.startDate,
                    endDate: data.endDate,
                  })
                }
              />
              <div className="status-selector">
                <StatusTypeSelector
                  onChange={handleStatusTypeChange}
                  statusTypes={[
                    "",
                    selfLinkVerifyStatus.INCOMPLETE,
                    selfLinkVerifyStatus.ROLLBACKED,
                    selfLinkVerifyStatus.VERIFIED,
                  ]}
                />
              </div>
            </div>
            <div className="flex items-center gap-2"></div>
          </div>

          {/* Table */}
          <div className="table-container w-full mt-5" style={{height: "45vh", overflow: "auto"}}>
            <table className="w-full">
              <tbody>
                <tr className="head-dashed-underline">
                  <th className="uppercase">Serial id</th>
                  <th className="uppercase">Link code</th>
                  <th className="uppercase">Documents</th>
                  <th className="uppercase">Status</th>
                  <th className="uppercase">Created at</th>
                  <th className="uppercase">Expired</th>
                  <th className="uppercase">Actions</th>
                </tr>
                {verificationLinkData?.data?.Items?.map(
                  (link, index) => {
                    return (
                      <tr key={index}>
                        {/* Add your row data here */}
                        <td>{index + 1}</td>
                        <td>{link.linkId}</td>
                        <td>
                          <div className="flex flex-col">
                            {Object.keys(link.documentTypes).map((type) => (
                              <span>{`${type} `}</span>
                            ))}
                          </div>
                        </td>
                        <td>
                          <div className="flex flex-col">
                            {Object.values(link.documentTypes).map(
                              (status: any) => (
                                <div
                                  className={`${
                                    status.status ===
                                    selfLinkVerifyStatus.VERIFIED
                                      ? "bg-green-600"
                                      : "bg-yellow-600"
                                  } m-1 py-1 px-2 rounded max-w-fit font-bold`}
                                >{`${status.status.toUpperCase()} `}</div>
                              )
                            )}
                          </div>
                        </td>
                        <td>{new Date(link.createdAt).toDateString()}</td>
                        <td>{link.isActive ? "No" : "Yes"}</td>
                        <td className="px-6 py-4 d-flex flex-col">
                          <div className="flex flex-col gap-2">
                            {/* <button
                              style={{
                                border: "1px solid white",
                                borderRadius: "2px",
                              }}
                              onClick={() => handleViewLink(link)}
                            >
                              view
                            </button> */}
                            <button
                              className="copy-bt bg-green-500"
                              onClick={() =>
                                handleCopyLink(
                                  `${window.location.host}/enduser/${link.linkId}`
                                )
                              }
                            >
                              Copy
                            </button>
                            <button
                              className="disable-bt"
                              onClick={() => {
                                if (link.isActive) {
                                  setConfirmationModalPayload(link);
                                  setConfirmationModalView(true);
                                } else {
                                  toast.error(
                                    `Link with the id ${link.linkId} is already disabled`
                                  );
                                }
                              }}
                            >
                              Disable
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  }
                )}
                {verificationLinkData?.data?.getVerifyLinksData?.Items
                  ?.length == 0 && (
                  <tr>
                    <td colSpan={7}>
                      <div className="justify-center items-center flex-col flex my-5">
                        <ImFileEmpty className="text-5xl mb-4" />
                        <div className=" font-bold text-xl">
                          No records found
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
                {isVerificationLinkLoading && (
                  <tr>
                    <td colSpan={7}>
                      <div
                        style={{
                          width: "100%",
                          height: "300px",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <ClockLoader color="white" size="20" />{" "}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex back-next-bt-wrapper justify-end gap-3 items-end mt-4">
          <div
            className="flex items-center"
            onClick={() => {
              setLastEvaluatedKey((olds: any) => {
                const newArr = JSON.parse(JSON.stringify(olds));
                return newArr?.length ? newArr?.slice(0, -1) : newArr;
              });
            }}
            style={{
              backgroundColor: LastEvaluatedKeys?.length
                ? "#009ef7"
                : "#2b2b40",
              color: LastEvaluatedKeys?.length ? "white" : "#7e8299",
            }}
          >
            Back
          </div>
          <div
            onClick={() => {
              if (verificationLinkData?.data?.LastEvaluatedKey) {
                setLastEvaluatedKey((olds) => {
                  return [...olds, verificationLinkData?.data?.LastEvaluatedKey];
                });
              }
            }}
            className={`flex items-center`}
            style={{
              backgroundColor: verificationLinkData?.data?.LastEvaluatedKey
                ? "#009ef7"
                : "#2b2b40",
              color: verificationLinkData?.data?.LastEvaluatedKey
                ? "white"
                : "#7e8299",
            }}
          >
            Next
          </div>
        </div>
      </div>

      <Modal open={showBuyTokens} onClose={() => setShowBuyTokens(false)}>
        <h1 style={{ color: "white" }}>
          Not enough tokens to continue with link generation
        </h1>
        <button
          className="blue-bt w-full"
          onClick={() => navigate("/wallet", { state: "buy-tokens" })}
        >
          Buy tokens
        </button>
      </Modal>

      <ConfirmationModal
        payload={confirmationModalPayload}
        open={confirmationModalView}
        onClose={() => {
          setConfirmationModalView(false);
          toast.error("exited delete wizard");
        }}
        message={"Are you sure"}
        onNo={() => {
          setConfirmationModalView(false);
          toast.error("cancelled delete");
        }}
        onYes={(payload) => {
          setConfirmationModalView(false);
          handleDisableLink(payload.linkId);
        }}
      />

      {viewLinkData && (
        <LinkDetails
          linkData={viewLinkData}
          open={viewLink}
          onClose={() => {
            setViewLink(false);
            setViewLinkData(null);
          }}
        />
      )}

      <Toaster
        toastOptions={{
          success: {
            duration: 3000,
          },
        }}
      />
    </Layout>
  );
}

//TODO? Merge this component to the parent component
interface StatusTypeSelectorProps {
  onChange: (statusType: string) => any;
  statusTypes: string[];
}
function StatusTypeSelector(props: StatusTypeSelectorProps) {
  const [statusType, setStatusType] = useState<string>(props.statusTypes[0]);

  function handleStatusTypeSelection(type: string) {
    setStatusType(type);
    props.onChange(type);
  }

  return (
    <select
      value={statusType}
      onChange={(e) => handleStatusTypeSelection(e.target.value)}
    >
      {props.statusTypes.map((type) => {
        if (type == "") {
          return <option value={type}>Filter by status</option>;
        } else {
          return <option value={type}>{type}</option>;
        }
      })}
    </select>
  );
}

export { VerificationLinkPage };
