import { useParams, useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
  queryKeys,
  getPublicLinkDetails,
  documentPublicEntry,
  documentTypeMap,
  s3PutDocument,
  getPresignedUrl,
} from "constant";
import toast, { Toaster } from "react-hot-toast";
import { useCallback, useEffect, useRef, useState } from "react";
import { HashLoader } from "react-spinners";
import { FcExpired } from "react-icons/fc";
import moment from "moment";

function EndUserPage() {
  const dlNumRef: any = useRef();
  const panNumRef: any = useRef();
  const dlDobRef: any = useRef();

  const { linkId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  console.log("link id", linkId);
  const { data, isLoading: isLinkDataLoading } = useQuery(
    [
      queryKeys.GET_ENDUSER_LINK_DETAILS,
      {
        linkId,
      },
    ],
    getPublicLinkDetails
  );
  console.log(data);

  const { mutate: mutateDocument, isLoading } = useMutation({
    mutationFn: documentPublicEntry,
    onSuccess: (data) => {
      if (
        (data?.data?.mapId === documentTypeMap.AADHAR ||
          data?.data?.mapId === documentTypeMap.DL ||
          data?.data?.mapId === documentTypeMap.PAN ||
          data?.data?.mapId === documentTypeMap.DIGI_EAADHAR) &&
        data?.data?.verified === "true"
      ) {
        toast.success("Document verification successful");
      } else if (data?.data?.mapId === documentTypeMap.DIGI_URL) {
        localStorage.setItem("digiRequestId", data?.data?.requestId);
        window.location.replace(data?.data?.url);
      } else {
        toast.error("Third Party Server Error");
      }
    },
    onError: () => {
      toast.error("Third Party Server Error");
    },
  });

  useEffect(() => {
    if (searchParams.get("confirmAuthorization") == "true" && linkId) {
      mutateDocument({
        documentId: localStorage.getItem("digiRequestId"), //  here document id is requestId
        linkId: linkId,
        documentType: documentTypeMap.DIGI_EAADHAR,
        mapId: documentTypeMap.AADHAR,
      });
      localStorage.removeItem("digiRequestId");
      setSearchParams({});
    }
  }, [linkId]);

  const AAdharVerifyCard = () => {
    return (
      <div className="verify-box">
        <h2>Verify with DigiLocker</h2>
        <button
          className="p-2 blue-bt digi-verify-bt"
          onClick={() =>
            mutateDocument({
              documentId: window.location.href, //  here document id is callback url
              linkId,
              documentType: documentTypeMap.DIGI_URL,
              mapId: documentTypeMap.DIGI_URL,
            })
          }
        >
          Verify
        </button>
      </div>
    );
  };

  const DLVerifyCard = () => {
    return (
      <div>
        <div className="verify-box">
          <h2>Verify with DL number</h2>
          <div className="adhaar-no-box">
            <h3>Enter Driving License Number</h3>
            <div className="adhaar-putin-box">
              <input className="border-2 w-full" ref={dlNumRef}></input>
              <p className="mt-1 mb-2">
                Enter a valid number for Driving License verification.
              </p>
            </div>

            <h3>Choose DOB</h3>
            <div className="adhaar-putin-box">
              <input
                type="date"
                className="border-2 w-full"
                ref={dlDobRef}
              ></input>

              <button
                onClick={() => {
                  if (!dlDobRef?.current?.value || !dlNumRef?.current?.value) {
                    return toast.error("All fields are mandatory");
                  }

                  mutateDocument({
                    documentId: dlNumRef?.current?.value,
                    linkId,
                    documentType: documentTypeMap.DL,
                    mapId: documentTypeMap.DL,
                    dob: moment(dlDobRef?.current?.value).format("DD/MM/YYYY"),
                  });
                }}
                className="blue-bt mt-5"
              >
                Verify
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return isLinkDataLoading || isLoading ? (
    <div className="flex h-[90vh] w-full items-center justify-center">
      <HashLoader color="#36d7b7" size="100" />
    </div>
  ) : data?.data?.isExpired ? (
    <div className="flex h-[90vh] w-full items-center justify-center">
      <div className="flex flex-col items-center justify-center">
        <FcExpired size="100" />
        <div className="font-bold text-4xl">Link Expired</div>
      </div>
    </div>
  ) : (
    <div className="h-[100vh] w-full">
      <div className="h-full w-44 left-panel">
        <div className="left-logo">
          <a href="" className="flex items-center justify-center">
            <img src="/images/black-icon-logo.png" alt="" />
          </a>
        </div>

        <div className="left-menu"></div>
      </div>

      <div className="w-full body-container h-screen p-20">
        {data?.data?.Item?.documentTypes &&
          Object.keys(data?.data?.Item?.documentTypes).map((item) => (
            <div className="max-w-xl mb-16">
              {item === documentTypeMap.AADHAR &&
                data?.data?.Item?.documentTypes[item].status ==
                  "incomplete" && <AAdharVerifyCard />}
              {item === documentTypeMap.DL &&
                data?.data?.Item?.documentTypes[item].status ==
                  "incomplete" && <DLVerifyCard />}
            </div>
          ))}
      </div>
    </div>
  );
}

export { EndUserPage };
