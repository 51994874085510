import { RegisterClientData } from "pages/types";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { convertHotelBnbsSchema } from "./utilsConvert";
import moment from "moment";
import clsx from "clsx";
import { ClockLoader } from "react-spinners";
import { extractVerifiedDocumentData } from "utils/dataProcessing";

function ConvertCustomer({
  onConvert,
  onCancel,
  isConverting,
  doc,
}: {
  onConvert: (registrationData: RegisterClientData) => any;
  onCancel: any;
  isConverting: any;
  doc: any;
}) {
  const presentDocumentData = extractVerifiedDocumentData(doc);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(convertHotelBnbsSchema),
    defaultValues: {
      advancedPaid: null,
      checkoutDate: new Date().toISOString().substring(0, 10),
      dailyCost: null,
      name: presentDocumentData.name,
      phoneNumber: "",
      roomNumber: "",
      roomType: "",
    },
  });
  const getRoomType = watch("roomType");
  const getRoomNumber = watch("roomNumber");
  const getPhoneNumber = watch("phoneNumber");
  const getName = watch("name");
  const getCheckoutDate = watch("checkoutDate");
  let getNoDays =
    moment(getCheckoutDate).diff(moment().startOf("day"), "days") + 1;
  const getDailyCost = watch("dailyCost");
  const getAdvancedPaid = watch("advancedPaid");
  const amountToPaid =
    Number(getDailyCost) * (getNoDays > 0 ? getNoDays : 1) -
    Number(getAdvancedPaid);

  const [amountExceeded, setAmountExceeded] = useState(false);

  useEffect(() => {
    setAmountExceeded(false);

    if (Math.sign(amountToPaid) === -1) {
      setAmountExceeded(true);
    }
    if (getNoDays > 60) setValue("checkoutDate", null);
  }, [amountToPaid, getNoDays]);

  function handleConvertCustomer() {
    // onConvert();
    if (isValid && !amountExceeded) {
      onConvert({
        advancedPaid: Number(getAdvancedPaid),
        checkoutDate: moment(getCheckoutDate).unix(),
        dailyCost: Number(getDailyCost),
        name: getName,
        phoneNumber: getPhoneNumber,
        roomNumber: getRoomNumber,
        roomType: getRoomType,
        noOfDays: getNoDays,
      });
    } else {
      console.log("inavalid form");
    }

    // console.log("convert object", {
    //   advancedPaid: getAdvancedPaid,
    //   checkoutDate: moment(getCheckoutDate).unix(),
    //   dailyCost: getDailyCost,
    //   name: getName,
    //   phoneNumber: getPhoneNumber,
    //   roomNumber: getRoomNumber,
    //   roomType: getRoomType,
    //   noOfDays: getNoDays,
    // });
  }

  return (
    <form onSubmit={handleSubmit(handleConvertCustomer)}>
      <div className="modal-wrapper flex flex-col">
        <div className="modal-title flex p-6 w-full">Onboard your customer</div>
        <div className="modal-body flex flex-col pt-0 p-6  w-full">
          <div className="convert-clients-wrap p-8">
            <div className="grid grid-cols-1 gap-4">
              <div className="flex flex-col">
                <label className="form-label fw-bolder text-dark fs-6">
                  Name:
                </label>
                <input
                  {...register("name")}
                  placeholder="Enter customer name"
                  type="text"
                  autoComplete="off"
                  className={clsx(
                    "form-control form-control-lg form-control-solid",
                    { "is-invalid": errors && errors.name },
                    { "is-valid": !errors && errors.name }
                  )}
                />
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{errors.name?.message}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-2 md:grid-cols-2 gap-6 mt-6">
              <div className="flex flex-col">
                <label className="form-label fw-bolder text-dark fs-6">
                  Phone Number
                </label>
                <input
                  {...register("phoneNumber")}
                  placeholder="Ex: 9012345678"
                  type="text"
                  autoComplete="off"
                  className={clsx(
                    "form-control form-control-lg form-control-solid",
                    { "is-invalid": errors && errors.phoneNumber },
                    { "is-valid": !errors && errors.phoneNumber }
                  )}
                />
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{errors.phoneNumber?.message}</span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col">
                <label className="form-label fw-bolder text-dark fs-6">
                  {"Room Number"}
                </label>
                <input
                  {...register("roomNumber")}
                  type="text"
                  autoComplete="off"
                  placeholder={"Ex: A-409"}
                  className={clsx(
                    "form-control form-control-lg form-control-solid",
                    { "is-invalid": errors && errors.roomNumber },
                    { "is-valid": !errors && errors.roomNumber }
                  )}
                />
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{errors.roomNumber?.message}</span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col">
                <label className="form-label fw-bolder text-dark fs-6">
                  {"Room Type"}
                </label>
                <input
                  {...register("roomType")}
                  type="text"
                  autoComplete="off"
                  placeholder={"Ex: Super Dulex"}
                  className={clsx(
                    "form-control form-control-lg form-control-solid",
                    { "is-invalid": errors && errors.roomType },
                    { "is-valid": !errors && errors.roomType }
                  )}
                />
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{errors.roomType?.message}</span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col">
                <label className="form-label fw-bolder text-dark fs-6">
                  {"Daily Cost (₹)"}
                </label>
                <input
                  {...register("dailyCost")}
                  placeholder="Ex: 500"
                  type="text"
                  autoComplete="off"
                  className={clsx(
                    "form-control form-control-lg form-control-solid",
                    { "is-invalid": errors && errors.dailyCost },
                    { "is-valid": !errors && errors.dailyCost }
                  )}
                />
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{errors.dailyCost?.message}</span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col">
                <label className="form-label fw-bolder text-dark fs-6">
                  {"Checkout date"}
                </label>
                <input
                  // id="input-date"
                  min={new Date().toISOString().substring(0, 10)}
                  {...register("checkoutDate")}
                  placeholder="0"
                  type="date"
                  autoComplete="off"
                  className={clsx(
                    "form-control form-control-lg form-control-solid text-uppercase",
                    { "is-invalid": errors && errors.checkoutDate },
                    { "is-valid": !errors && errors.checkoutDate }
                  )}
                />
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{errors.checkoutDate?.message}</span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col">
                <label className="form-label fw-bolder text-dark fs-6">
                  Advanced Paid(₹)
                </label>
                <input
                  {...register("advancedPaid")}
                  placeholder="Enter advanced paid if any"
                  type="text"
                  autoComplete="off"
                  className={clsx(
                    "form-control form-control-lg form-control-solid",
                    { "is-invalid": errors && errors.advancedPaid },
                    { "is-valid": !errors && errors.advancedPaid }
                  )}
                />
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{errors.advancedPaid?.message}</span>
                    <span style={{ color: "red" }}>
                      {amountExceeded &&
                        `The advance amount exceed by Rs.${amountToPaid * -1}`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-4">
              <div className="flex no-days flex-col">
                <div className="mb-5 mt-2">
                  <p>
                    <span className="col-lg-4 fw-bold text-muted fs-6">
                      No of Days:
                    </span>
                    &nbsp;{" "}
                    <b className="fs-7">{isNaN(getNoDays) ? 0 : getNoDays}</b>{" "}
                  </p>
                </div>
                {!amountExceeded && (
                  <div className="mb-5">
                    <p>
                      <span className="col-lg-4 fw-bold text-muted fs-6">
                        Amount to be Paid (Client):
                      </span>
                      &nbsp;{" "}
                      <b className="fs-7">
                        {isNaN(amountToPaid) ? 0 : "₹" + amountToPaid}
                      </b>{" "}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="modal-footer flex justify-end p-6 w-full gap-2">
          <button
            type="submit"
            className="blue-bt assign-bt flex items-center"
            onClick={handleConvertCustomer}
            disabled={isConverting}
          >
            {isConverting ? <ClockLoader color="white" size={20} /> : "Register guest"}
          </button>
          <button
            type="button"
            disabled={isConverting}
            className="cancel-bt flex items-center"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </form>
  );
}

export default ConvertCustomer;
