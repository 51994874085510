import { Outlet } from "react-router-dom";
import { userDetails, queryKeys, userRoles } from "constant";
import { useQuery } from "react-query";

function AuthWrapper() {
  useQuery(
    [queryKeys.USER_DETAILS, { path: window.location.pathname }],
    userDetails,
    {
      onSuccess: (data) => {
        if (
          !data?.data?.role &&
          window.location.pathname !== "/login" &&
          window.location.pathname !== "/signup"
        ) {
          window.location.replace("/login");
        } else if (
          data?.data?.role === userRoles.ADMIN &&
          !data?.data?.companyId?.length &&
          window.location.pathname !== "/company/create"
        ) {
          window.location.replace("/company/create");
        }

        // if(data?.data?.role === userRoles.STAFF && !data?.data?.companyId?.length){
        //   // sorry you are currently not joined any company
        //   //remove staff role option
        //   // window.location.replace("/company/create");
        // }
      },
      onError: () => {
        if (
          window.location.pathname !== "/login" &&
          window.location.pathname !== "/signup"
        ) {
          window.location.replace("/login");
        }
      },
    }
  );

  return (
    <>
      <Outlet />
    </>
  );
}

export { AuthWrapper };
