import * as Form from "@radix-ui/react-form";
import { useMutation } from "react-query";
import { createCompany, companyTypes } from "constant";
import toast, { Toaster } from "react-hot-toast";

function CompanyCreatePage() {
  const { mutate: mutateCompany, isLoading: isCreating } = useMutation({
    mutationFn: createCompany,
    onSuccess: (data) => {
      toast.success("Company created successfully.");

      // window.alert("wait, response data" + JSON.stringify(data));
      window.location.replace("/");
    },
    onError: () => {
      toast.error("Failed to create company.");
    },
  });

  const submitHandler = async (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    const formObject = Object.fromEntries(data.entries());
    console.log("form object", formObject);
    // window.alert("wait" + `${JSON.stringify(formObject)}`);
    mutateCompany({ queryKey: ["", formObject] });
    return false;
  };

  return (
    <div className="login-box create-wrap">
      <a href="" className="logo flex items-center justify-center">
        <img src="/images/spaceshift_logo.png" alt="" />
      </a>
      <div className="login-box-inner">
        <Toaster />
        <Form.Root className="input-box" onSubmit={submitHandler}>
          <Form.Field className="grid mb-[10px]" name="name">
            <div className="flex items-baseline justify-between">
              <Form.Label className="text-[15px] font-medium leading-[35px] text-black">
                Company Name
              </Form.Label>
              <Form.Message
                className="text-[13px] text-black opacity-[0.8]"
                match="valueMissing"
              >
                Please provide company name
              </Form.Message>
            </div>
            <Form.Control asChild>
              <input className="" type="text" required />
            </Form.Control>
          </Form.Field>

          <Form.Field className="grid mb-[10px]" name="type">
            <div className="flex items-baseline justify-between flex-col">
              <Form.Label className="text-[15px] font-medium leading-[35px] text-black">
                
                type ({companyTypes.HOTEL}/{companyTypes.RENTAL})
              </Form.Label>
              {/* <select required className="form-select form-control-lg w-full">
                <option value="">Type</option>
                <option value="">({companyTypes.HOTEL})</option>
                <option value="">({companyTypes.RENTAL})</option>
              </select> */}
              <Form.Message
                className="text-[13px] text-black opacity-[0.8]"
                match="valueMissing"
              >
                Please provide company type
              </Form.Message>
            </div>
            <Form.Control asChild>
              <input className="" type="text" required />
            </Form.Control>
          </Form.Field>

          <Form.Field className="grid mb-[10px]" name="address">
            <div className="flex items-baseline justify-between">
              <Form.Label className="text-[15px] font-medium leading-[35px] text-black">
                Address
              </Form.Label>
              <Form.Message
                className="text-[13px] text-black opacity-[0.8]"
                match="valueMissing"
              >
                Please provide address
              </Form.Message>
            </div>
            <Form.Control asChild>
              <input className="" type="text" required />
            </Form.Control>
          </Form.Field>

          <Form.Field className="grid mb-[10px]" name="state">
            <div className="flex items-baseline justify-between">
              <Form.Label className="text-[15px] font-medium leading-[35px] text-black">
                State
              </Form.Label>
              <Form.Message
                className="text-[13px] text-black opacity-[0.8]"
                match="valueMissing"
              >
                Please provide state
              </Form.Message>
            </div>
            <Form.Control asChild>
              <select required className="form-select form-control-lg">
                <option value="">Select State</option>
                <option value="Andaman and Nicobar Islands">
                  Andaman and Nicobar Islands
                </option>
                <option value="Andhra Pradesh">Andhra Pradesh</option>
                <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                <option value="Assam">Assam</option>
                <option value="Bihar">Bihar</option>
                <option value="Chandigarh">Chandigarh</option>
                <option value="Chhattisgarh">Chhattisgarh</option>
                <option value="Dadra and Nagar Haveli">
                  Dadra and Nagar Haveli
                </option>
                <option value="Daman and Diu">Daman and Diu</option>
                <option value="Delhi">Delhi</option>
                <option value="Goa">Goa</option>
                <option value="Gujarat">Gujarat</option>
                <option value="Haryana">Haryana</option>
                <option value="Himachal Pradesh">Himachal Pradesh</option>
                <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                <option value="Jharkhand">Jharkhand</option>
                <option value="Karnataka">Karnataka</option>
                <option value="Kerala">Kerala</option>
                <option value="Lakshadweep">Lakshadweep</option>
                <option value="Madhya Pradesh">Madhya Pradesh</option>
                <option value="Maharashtra">Maharashtra</option>
                <option value="Manipur">Manipur</option>
                <option value="Meghalaya">Meghalaya</option>
                <option value="Mizoram">Mizoram</option>
                <option value="Nagaland">Nagaland</option>
                <option value="Odisha">Odisha</option>
                <option value="Puducherry">Puducherry</option>
                <option value="Punjab">Punjab</option>
                <option value="Rajasthan">Rajasthan</option>
                <option value="Sikkim">Sikkim</option>
                <option value="Tamil Nadu">Tamil Nadu</option>
                <option value="Telangana">Telangana</option>
                <option value="Tripura">Tripura</option>
                <option value="Uttar Pradesh">Uttar Pradesh</option>
                <option value="Uttarakhand">Uttarakhand</option>
                <option value="West Bengal">West Bengal</option>
              </select>
            </Form.Control>
          </Form.Field>

          <Form.Field className="grid mb-[10px]" name="zip">
            <div className="flex items-baseline justify-between">
              <Form.Label className="text-[15px] font-medium leading-[35px] text-black">
                zip
              </Form.Label>
              <Form.Message
                className="text-[13px] text-black opacity-[0.8]"
                match="valueMissing"
              >
                Please provide company type
              </Form.Message>
            </div>

            <Form.Control asChild>
              <input className="" type="text" required />
            </Form.Control>
          </Form.Field>

          <Form.Field className="grid mb-[10px]" name="coupon">
            <div className="flex items-baseline justify-between">
              <Form.Label className="text-[15px] font-medium leading-[35px] text-black">
                Coupon
              </Form.Label>
            </div>

            <Form.Control asChild>
              <input className="" type="text" />
            </Form.Control>
          </Form.Field>

          <Form.Submit asChild>
            <button className="blue-bt w-full">Create</button>
          </Form.Submit>
        </Form.Root>
      </div>
    </div>
  );
}

export { CompanyCreatePage };
