import { Layout } from "components";
import ConfirmationModal from "components/ConfirmationModal";
import {
  createCoupon,
  createCouponPrefix,
  getCoupons,
  queryKeys,
} from "constant";
import moment from "moment";
import React, { useRef, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useMutation, useQuery } from "react-query";
import Modal from "react-responsive-modal";
import { ClockLoader } from "react-spinners";
import { ImFileEmpty } from "react-icons/im";

function CreateCouponPage() {
  const [coupon, setCoupon] = useState<any>();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [modalPayload, setModalPayload] = useState<any>();

  const onCloseModal = () => {
    setCoupon(null);
    setOpen(false);
  };

  const onOpenModal2 = () => setOpen2(true);
  const onCloseModal2 = () => setOpen2(false);

  const {
    mutate: mutateCreateCouponPrefix,
    isLoading: isCreatingCouponPrefix,
  } = useMutation({
    mutationFn: createCouponPrefix,
    onSuccess: (data) => {
      console.log(data);
      if (data.message.includes("nable")) {
        toast.error(data.message);
      } else {
        toast.success(data.message);
      }
      onCloseModal2();
      couponsDataRefetch();
    },
    onError: () => {
      toast.error("Failed to create coupon.");
    },
  });

  const {
    data: couponsData,
    isLoading: isCouponsDataLoading,
    refetch: couponsDataRefetch,
  }: any = useQuery([queryKeys.GET_COUPONS], getCoupons);

  return (
    <Layout>
      <div className="p-7 body-bg">
        <div className="all-verified-documents-wrap flex p-7 mt-8 flex-col">
          <div className="flex justify-between items-center mb-6">
            {/* Heading */}

            <h2>Coupons</h2>

            {/* Button on the far left of the heading */}
            <a className="convert-bt flex items-center" onClick={onOpenModal2}>
              + Create coupon prefix
            </a>
          </div>

          {/* Table */}

          <div className="table-container w-full">
            <table className="w-full">
              {/* Table Head */}
              <thead>
                <tr className="head-dashed-underline">
                  {/* Add your column headers here */}
                  <th className="uppercase text-left">Coupon prefix</th>
                  <th className="uppercase text-left">Created on</th>
                  <th className="uppercase text-left">Credit token</th>
                </tr>
              </thead>

              {/* Table Body */}
              <tbody>
                {couponsData?.data?.Items.map((item, key) => {
                  return (
                    <tr id={key}>
                      <td className="text-left">{item?.couponPrefix}</td>
                      <td className="">
                        {moment(item?.createdAt).format("DD/MM/YYYY")}
                      </td>
                      <td className="">{item?.creditToken}</td>
                    </tr>
                  );
                })}
                <tr>
                  {couponsData?.data?.Items.length == 0 && (
                    <td colSpan={7}>
                     <div className="justify-center items-center flex-col flex my-5">
                        <ImFileEmpty className="text-5xl mb-4" />
                        <div className=" font-bold text-xl">
                          No records found
                        </div>
                      </div>
                    </td>
                  )}
                  {isCouponsDataLoading && (
                    <td colSpan={7}>
                      <div
                        style={{
                          width: "100%",
                          height: "300px",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <ClockLoader color="white" size="20" />{" "}
                      </div>
                    </td>
                  )}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div>
        <Modal open={open} onClose={onCloseModal} center>
          {coupon && (
            <div className="modal-wrapper flex flex-col">
              <div className="modal-title flex p-6 w-full">
                View Reseller Details
              </div>

              <div className="modal-footer flex justify-end p-6 w-full">
                <a
                  className="modal-close-bt flex items-center"
                  onClick={onCloseModal}
                >
                  Close
                </a>
              </div>
            </div>
          )}
        </Modal>

        <Modal open={open2} onClose={onCloseModal2} center>
          <CreateCoupon
            onCouponCreation={(createdCoupon) => {
              mutateCreateCouponPrefix({
                couponPrefix: createdCoupon.couponPrefix,
                creditToken: createdCoupon.creditToken,
              });
            }}
            onCancel={onCloseModal2}
          />
        </Modal>
      </div>

      <Toaster
        toastOptions={{
          success: {
            duration: 3000,
          },
        }}
      />

      <ConfirmationModal
        payload={modalPayload}
        open={confirmationModal}
        onClose={() => setConfirmationModal(false)}
        message={"Are you sure"}
        onNo={() => setConfirmationModal(false)}
        onYes={(payload: any) => {
          console.log("reseller to delete", payload);
          // const deleteStaffArgs: RemoveStaffArgs = {
          //   companyId: payload.companyId,
          //   staffId: payload.userId,
          // };

          // mutateDeleteStaff({ queryKey: ["", deleteStaffArgs] });
          console.log("delete the coupon :", payload);

          setConfirmationModal(false);
        }}
      />
    </Layout>
  );
}

interface CreatedCoupon {
  couponPrefix: string;
  creditToken: number;
}
function CreateCoupon(props: {
  onCouponCreation: (coupon: CreatedCoupon) => any;
  onCancel: () => any;
}) {
  const couponPrefixRef: any = useRef();
  const creditTokenRef: any = useRef();
  const [isInputValid, setIsInputValid] = useState(true);

  function handleCouponCreation() {
    const coupon: CreatedCoupon = {
      couponPrefix: couponPrefixRef?.current?.value,
      creditToken: creditTokenRef?.current?.value,
    };

    if (coupon.couponPrefix && coupon.creditToken) {
      return props.onCouponCreation(coupon);
    }

    setIsInputValid(false);
  }

  return (
    <div className="modal-wrapper flex flex-col">
      <div className="modal-title flex p-6 w-full">Create coupon</div>
      <div className="modal-body flex flex-col pt-0 p-6  w-full">
        <div className="convert-clients-wrap p-8">
          {/* <div className="grid grid-cols-1 gap-4">
            <div className="flex flex-col">
              <label htmlFor="" className="mb-2">
                First Name
              </label>
              <input type="text" placeholder="Enter client name" />
            </div>
          </div> */}

          <div className="grid grid-cols-2 md:grid-cols-2 gap-6 mt-6">
            <div className="flex flex-col">
              <label htmlFor="" className="mb-2">
                Coupon prefix
              </label>
              <input
                ref={couponPrefixRef}
                type="text"
                placeholder="Ex: Welcome 30"
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor="" className="mb-2">
                Token
              </label>
              <input
                autoComplete="off"
                ref={creditTokenRef}
                type="text"
                placeholder="Ex: 50"
              />
            </div>
          </div>
          {!isInputValid && <span>Please enter all the fields</span>}
        </div>
      </div>

      <div className="modal-footer flex justify-end p-6 w-full gap-2">
        <button
          className="assign-bt flex items-center"
          onClick={handleCouponCreation}
        >
          Create
        </button>
        <a className="cancel-bt flex items-center" onClick={props.onCancel}>
          Discard
        </a>
      </div>
    </div>
  );
}

export { CreateCouponPage };
