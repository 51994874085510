import moment from "moment";
import { CustomerData } from "pages/types";
import { useEffect, useRef, useState } from "react";
import { getNumberOfDays } from "utils/timeUtils";

function EditCustomerDetails({
  customer,
  onUpdate,
  onClose,
}: {
  customer: CustomerData;
  onUpdate: (data: {
    roomType: string;
    roomNumber: string;
    checkoutDate: number;
    advancedPaid: number;
  }) => any;
  onClose: () => any;
}) {
  const { meta, returnDate, createdAt, ...restObj } = customer;
  const { name, advancedPaid, dailyCost, roomType, roomNumber, noOfDays } =
    meta;

  const amountToPaid = dailyCost * noOfDays - advancedPaid;
  const [newReturnDate, setNewReturnDate] = useState({
    date: null,
    newAmount: null,
    getNoDays: noOfDays,
  });
  const [btnHandler, setBtnHandler] = useState({
    txt: "Update",
    loader: false,
  });
  const { txt, loader } = btnHandler;
  const [updateAdvance, setUpdateAdvance] = useState<any>(advancedPaid);
  const [room, setRoom] = useState({
    roomType: roomType,
    roomNumber: roomNumber,
  });

  useEffect(() => {
    // if (newReturnDate.date || updateAdvance) {
    let getNoDays =
      moment(newReturnDate.date || returnDate).diff(
        moment(createdAt).startOf("day"),
        "days"
      ) + 1;
    let newAmount =
      dailyCost * getNoDays - (isNaN(updateAdvance) ? 0 : updateAdvance);

    setNewReturnDate({ ...newReturnDate, newAmount, getNoDays });
    // }
  }, [newReturnDate.date, updateAdvance]);

  const roomTypeRef: any = useRef();
  const roomNumberRef: any = useRef();
  const checkoutDateRef: any = useRef();
  const advancedPaidRef: any = useRef();

  const handleUpdate = () => {
    onUpdate({
      roomType: roomTypeRef?.current?.value,
      roomNumber: roomNumberRef?.current?.value,
      checkoutDate: new Date(checkoutDateRef?.current?.value).getTime(),
      advancedPaid: Number(advancedPaidRef?.current?.value),
    });
  };

  return (
    <div className="modal-wrapper flex flex-col">
      <div className="modal-title flex p-6 w-full">
        Client Documents Details
      </div>

      <div className="modal-body flex flex-col pt-0  w-full">
        <div className="convert-clients-wrap p-8">
          <div className="flex flex-col">
            <div className="grid grid-cols-2 md:grid-cols-2 gap-6 mt-6">
              <div className="flex justify-between text-white text-sm">
                <span className="fw-bold text-muted fs-6 ">
                  Client Name: &nbsp;
                </span>
                {name}
              </div>
              <div className="flex justify-between text-white text-sm">
                <span className="fw-bold text-muted fs-6">
                  Advanced Paid: &nbsp;
                </span>{" "}
                <b className={`text-${advancedPaid * 1 > 0 && "success"}`}>
                  {advancedPaid}
                </b>
              </div>
              <div className="flex justify-between text-white text-sm">
                <span className="fw-bold text-muted fs-6">
                  No of Days: &nbsp;
                </span>
                {noOfDays}
              </div>

              <div className="flex justify-between text-white text-sm">
                <span className="fw-bold text-muted fs-6">
                  Remaining to be Paid: &nbsp;
                </span>{" "}
                <b>{noOfDays * dailyCost - advancedPaid}</b>
              </div>

              <div className="flex flex-col">
                <label
                  className="form-label fw-bolder fs-6 mb-2"
                  style={{ color: "white" }}
                >
                  Room Type
                </label>
                <input
                  defaultValue={room.roomType}
                  ref={roomTypeRef}
                  onChange={(e) =>
                    setRoom({ ...room, roomType: e.target.value })
                  }
                  required={true}
                  placeholder="Update advanced paid"
                  type="text"
                  autoComplete="off"
                  className="form-control form-control-lg form-control-solid"
                />
              </div>
              <div className="flex flex-col">
                <label
                  className="form-label fw-bolder text-muted fs-6 mb-2"
                  style={{ color: "white" }}
                >
                  Room Number
                </label>
                <input
                  ref={roomNumberRef}
                  defaultValue={room.roomNumber}
                  onChange={(e) =>
                    setRoom({ ...room, roomNumber: e.target.value })
                  }
                  placeholder="Update advanced paid"
                  type="text"
                  autoComplete="off"
                  className="form-control form-control-lg form-control-solid"
                  required={true}
                />
              </div>

              <div className="flex flex-col">
                <label
                  className="form-label fw-bolder text-muted fs-6 mb-2"
                  style={{ color: "white" }}
                >
                  Checkout on
                </label>
                {
                  <input
                    ref={checkoutDateRef}
                    onKeyDown={(e) => e.preventDefault()}
                    onChange={(e) =>
                      setNewReturnDate({
                        ...newReturnDate,
                        date: e.target.value,
                      })
                    }
                    defaultValue={moment(returnDate).format("YYYY-MM-DD")}
                    id="input-date"
                    type="date"
                    min={moment().add(0, "day").format("YYYY-MM-DD")}
                    autoComplete="off"
                    className="form-control form-control-lg form-control-solid text-uppercase"
                  />
                }
                {newReturnDate.newAmount !== null &&
                  amountToPaid !== newReturnDate.newAmount && (
                    <div>
                      {Math.sign(newReturnDate.newAmount) === -1 ? (
                        <p className="mt-5 text-red-800 fw-bolder">
                          Amount to be paid to the customer:{" "}
                          <span className="text-primary">
                            {" "}
                            {newReturnDate.newAmount * -1}
                          </span>
                        </p>
                      ) : (
                        <p className="mt-5 text-gray-800 fw-bolder">
                          New Amount to be Paid:{" "}
                          <span className="text-primary">
                            {" "}
                            {newReturnDate.newAmount}
                          </span>
                        </p>
                      )}
                      <p className="mt-5 text-gray-800 fw-bolder">
                        New number of days:{" "}
                        <span className="text-primary">
                          {" "}
                          {moment(newReturnDate.date || returnDate).diff(
                            moment(createdAt).startOf("day"),
                            "days"
                          ) + 1}
                        </span>
                      </p>
                      <p className="mt-5 text-gray-800 fw-bolder">
                        Daily cost:{" "}
                        <span className="text-primary"> {dailyCost}</span>
                      </p>
                    </div>
                  )}
              </div>
              <div className="flex flex-col">
                <label
                  className="form-label fw-bolder text-muted fs-6 mb-2"
                  style={{ color: "white" }}
                >
                  Advanced Paid
                </label>
                <input
                  ref={advancedPaidRef}
                  defaultValue={updateAdvance}
                  onChange={(e) => setUpdateAdvance(e.target.value)}
                  placeholder="Update advanced paid"
                  type="text"
                  autoComplete="off"
                  className="form-control form-control-lg form-control-solid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal-footer flex justify-end p-6 w-full gap-2">
        
        <a className="cancel-bt flex items-center" onClick={onClose}>
          Cancel
        </a>
        <a className="blue-bt flex items-center" onClick={handleUpdate}>
          Update
        </a>
      </div>
    </div>
  );
}

export default EditCustomerDetails;
