import React, { useEffect, useRef, useState } from "react";
import { DatePicker, Layout } from "components";
import {
  getDocumentEntries,
  documentTypeMap,
  userDetails,
  queryKeys,
  userRoles,
  getCustomers,
  deleteCustomer,
  DeleteCustomerArgs,
} from "constant";
import { useMutation, useQuery } from "react-query";
import {
  CustomerData,
  DateRange,
  DateRangeUnix,
  DocumentData,
} from "pages/types";
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";
import ReactDOM from "react-dom";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Loader from "components/Loader";
import DocumentCard from "../CustomerDetails/DocumentCard";
import { Button } from "@radix-ui/themes";
import ConfirmationModal from "components/ConfirmationModal";
import { ClockLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import ActiveCustomers from "./ActiveCustomers";
import InActiveCustomers from "./InactiveCustomers";
import { BaseButton } from "@radix-ui/themes/dist/cjs/components/base-button";

export const dateTypes = {
  reset: "",
  createdDate: "CREATED_DATE",
  returnedDate: "RETURNED_DATE",
};

function getNumberOfDays(customer: CustomerData) {
  const checkoutDate = new Date(customer.meta.checkoutDate);
  const checkInDate = new Date(customer.createdAt);

  const timeDifference = checkoutDate.getTime() - checkInDate.getTime();
  const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  return daysDifference;
}

function CustomersPage() {
  const [refreshActiveCustomers, setRefreshActiveCustomers] = useState(false);

  function handleCustomerDelete() {
    setRefreshActiveCustomers((prev) => !prev);
  }

  return (
    <Layout>
      <div className="p-7 body-bg">
        <ActiveCustomers onCustomerDelete={handleCustomerDelete} />
        <InActiveCustomers refreshCustomers={refreshActiveCustomers} />
      </div>
    </Layout>
  );
}

export { CustomersPage };
