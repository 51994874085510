import * as Form from "@radix-ui/react-form";
import { useMutation } from "react-query";
import { createUser } from "constant";
import toast, { Toaster } from "react-hot-toast";

function SignupPage() {
  const { mutate: mutateSignup, isLoading: isSigningUp } = useMutation({
    mutationFn: createUser,
    onSuccess: (data) => {
      toast.success("User created successfully.");
      window.location.replace("/login");
    },
    onError: () => {
      toast.error("Failed to create user.");
    },
  });

  const submitHandler = async (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    const formObject = Object.fromEntries(data.entries());
    console.log(formObject);
    mutateSignup({ queryKey: ["", formObject] });
    return false;
  };

  return (
    <div className="login-box">
    <a href="" className="logo flex items-center justify-center">
    <img src="/images/spaceshift_logo.png" alt="" />
    </a>
    <div className="login-box-inner">
      <Toaster />
      <Form.Root className="input-box" onSubmit={submitHandler}>
        <Form.Field className="grid mb-[10px]" name="name">
          <div className="flex items-baseline justify-between">
            <Form.Label className="text-[15px] font-medium leading-[35px] text-black">
              Full name
            </Form.Label>
            <Form.Message
              className="text-[13px] text-black opacity-[0.8]"
              match="valueMissing"
            >
              Please enter your name
            </Form.Message>
          </div>
          <Form.Control asChild>
            <input
              className=""
              type="text"
              required
            />
          </Form.Control>
        </Form.Field>

        <Form.Field className="grid mb-[10px]" name="phone">
          <div className="flex items-baseline justify-between">
            <Form.Label className="text-[15px] font-medium leading-[35px] text-black">
              Phone number
            </Form.Label>
            <Form.Message
              className="text-[13px] text-black opacity-[0.8]"
              match="valueMissing"
            >
              Please enter your phone number
            </Form.Message>
            <Form.Message
              className="text-[13px] text-black opacity-[0.8]"
              match={(value, formData) => value.length !== 10}
            >
              Please provide a valid phone number
            </Form.Message>
          </div>
          <Form.Control asChild>
            <input
              className=""
              type="tel"
              required
            />
          </Form.Control>
        </Form.Field>

        <Form.Field className="grid mb-[10px]" name="password">
          <div className="flex items-baseline justify-between">
            <Form.Label className="text-[15px] font-medium leading-[35px] text-black">
              Password
            </Form.Label>
            <Form.Message
              className="text-[13px] text-black opacity-[0.8]"
              match="valueMissing"
            >
              Please enter password
            </Form.Message>
          </div>
          <Form.Control asChild>
            <input
              className=""
              type="password"
              required
            />
          </Form.Control>
        </Form.Field>

        <Form.Submit asChild>
          <button className="blue-bt w-full mt-5">
            Signup
          </button>
        </Form.Submit>
      </Form.Root>
      <div className="flex flex-col justify-center items-center gap-3 pt-5">
      <span className="text-sm">
        Already a user? <a href="/login" className=""> <b className="text-blue-600 capitalize">login</b> </a>
      </span>
    </div>
    </div>
     </div>
  );
}

export { SignupPage };
