import { documentTypeMap, documentModeMap } from "constant";
import AdhaarCard from "./AdhaarCard";
import PanCard from "./PanCard";
import DrivingLicenseCard from "./DrivingLicenseCard";
import AdhaarOtpCard from "./AdhaarOtpCard";
import {ManualCard} from "./ManualCard"

function DocumentDisplayCard(props: any) {
  function renderDocCard() {
    if (props.doc) {
      switch (props.doc.mode) {
        case documentModeMap[documentTypeMap.AADHAR].mode:
          return <AdhaarCard doc={props.doc} />;
        case documentModeMap[documentTypeMap.PAN].mode:
          return <PanCard doc={props.doc} />;
        case documentModeMap[documentTypeMap.DL].mode:
          return <DrivingLicenseCard doc={props.doc} />;
        case documentModeMap[documentTypeMap.DIGI_EAADHAR].mode:
          return <AdhaarOtpCard doc={props.doc} />;
        case documentModeMap[documentTypeMap.MANUAL].mode:
          return <ManualCard doc={props.doc} />;
        default:
          return <></>;
      }
    }
  }

  return (
   <div className="display-wrap">
      <div className="all-verified-documents-wrap flex p-7 m-8 flex-col">
        {renderDocCard()}
      </div>
      </div>
  );
}

function DataField({ title, children }: { title?: string; children?: any }) {
  return (
    <span className="flex">
      {title && <h3>{title}</h3>}
      {children && <h4> {children}</h4>}
    </span>
  );
}

export { DocumentDisplayCard, DataField };
