import { Layout } from "components";
import {
  createCoupon,
  getResellerSales,
  getCoupons,
  getResellerCoupon,
  queryKeys,
  userDetails,
} from "constant";
import moment from "moment";
import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useMutation, useQuery } from "react-query";
import Modal from "react-responsive-modal";
import { MoonLoader, ClockLoader } from "react-spinners";
import { ImFileEmpty } from "react-icons/im";

interface CouponData {
  couponPrefix: string;
  creditToken: number;
}
interface CouponItemsData {
  createdAt: number;
  couponId: string;
  resellerId: string;
  createdBy: {
    name: string;
    id: string;
    role: string;
  };
  creditToken: 50;
}

interface CouponSalesData {
  zip: string;
  companyId: string;
  staffIds: string[];
  createdAt: number;
  address: string;
  email: string;
  name: string;
  coupon: string;
  state: string;
  disabledStaffs: string[];
  adminId: string;
  type: string;
}

function Generatecoupon() {
  const [purchaseTokenModal, setPurchaseTokenModal] = useState(false);
  const [invoiceFilter, setInvoiceFilter] = useState(false);
  const [viewCoupon, setViewCoupon] = useState("");
  const [showCouponDetailsModal, setShowCouponDetailsModal] = useState(false);

  const { data: couponsData, isLoading: isCouponsDataLoading }: any = useQuery(
    [queryKeys.GET_COUPONS],
    getCoupons
  );
  console.log("available coupons", couponsData);

  const {
    data: resellerCouponsData,
    isLoading: isResellerCouponsDataLoading,
    refetch: refetchResellerCouponsData,
  }: any = useQuery([queryKeys.GET_RESELLER_COUPONS], getResellerCoupon);
  console.log("reseller coupon", resellerCouponsData);

  const { data: userData }: any = useQuery(
    [queryKeys.USER_DETAILS],
    userDetails
  );

  const { data: couponSalesData, isLoading: isCouponSalesDataLoading }: any =
    useQuery(
      [
        queryKeys.GET_COUPON_SALES,
        {
          couponId: viewCoupon,
        },
      ],
      getResellerSales,
      { enabled: !!userData?.data?.userId?.length }
    );
  console.log("coupons sales data", couponSalesData);

  const { mutate: mutateCreateCoupon, isLoading: isCouponCreating } =
    useMutation({
      mutationFn: createCoupon,
      onSuccess: (data) => {
        setPurchaseTokenModal(false);
        refetchResellerCouponsData();
        toast.success(data.message);
      },
      onError: (error) => {
        toast.error(JSON.stringify(error));
      },
    });

  function handleCouponCreation(couponPrefix: string) {
    mutateCreateCoupon({
      couponPrefix: couponPrefix,
    });
  }

  return (
    <Layout>
      <div className="p-7 body-bg">
        {/* Generate coupon card */}

        <div className="all-verified-documents-wrap flex p-7 mt-2 flex-col">
          <div className="flex justify-between items-center mb-6">
            <h3 className="mb-3 text-xl font-semibold text-white">
              Coupons Management
            </h3>

            <button
              className="generate-new-coupon-title blue-bt"
              onClick={() => setPurchaseTokenModal(true)}
            >
              + Generate new coupon
            </button>
          </div>
          {/* Generated coupons table */}
          <div className="table-container w-full">
            <table className="w-full">
              <thead>
                <tr className="head-dashed-underline">
                  <th className="uppercase">Id</th>
                  <th className="uppercase">Created at</th>
                  <th className="uppercase">Credit token</th>
                  <th className="uppercase">Is used</th>
                  {/* <th className="uppercase">Actions</th> */}
                </tr>
              </thead>
              <tbody>
                {resellerCouponsData?.data?.Items?.map((couponData: any) => (
                  <tr>
                    <td>{couponData.couponId}</td>
                    <td>{moment(couponData.createdAt).format("DD/MM/YYYY")}</td>
                    <td>{couponData.creditToken}</td>
                    <td>{couponData?.isUsed ? "YES" : "NO"}</td>
                    {/* <td>
                        <button
                          onClick={() => {
                            console.log("delete coupon");
                          }}
                        >
                          Delete
                        </button>
                      </td> */}
                  </tr>
                ))}

                {resellerCouponsData?.data?.Items?.length == 0 && (
                  <tr>
                    <td colSpan={10}>
                      <div className="justify-center items-center flex-col flex my-5">
                        <ImFileEmpty className="text-5xl mb-4" />
                        <div className=" font-bold text-xl">
                          No records found
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
                {isResellerCouponsDataLoading && (
                  <tr>
                    <td colSpan={10}>
                      <div
                        style={{
                          width: "100%",
                          height: "300px",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <ClockLoader color="white" size="20" />{" "}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            <Toaster
              toastOptions={{
                success: {
                  duration: 3000,
                },
              }}
            />

            {/* Token purchase modal */}
            <Modal
              open={purchaseTokenModal}
              onClose={() => setPurchaseTokenModal(false)}
              center
              classNames={{
                modal: "customModal",
              }}
            >
              <div className="flex flex-col gap-4 justify-center py-4 text-white">
                {couponsData?.data?.Items?.map((token) => (
                  <Token
                    data={token}
                    onPurchase={(data) =>
                      handleCouponCreation(data.couponPrefix)
                    }
                  />
                ))}
              </div>
            </Modal>

            {/* Coupon details modal */}
            <Modal
              open={showCouponDetailsModal}
              onClose={() => setShowCouponDetailsModal(false)}
              center
            >
              <div className="table-container w-full">
                {/* Generated coupons table */}
                <table className="w-full">
                  <thead>
                    <tr className="head-bg">
                      <th>Company name</th>
                      <th>Comapny address</th>
                      <th>Created at</th>
                    </tr>
                  </thead>
                  <tbody>
                    {couponSalesData?.data?.Items?.map(
                      (saleData: CouponSalesData) => (
                        <tr className="flex flex-row">
                          <td>{saleData.name}</td>
                          <td>{saleData.address}</td>
                          <td>
                            {moment(saleData.createdAt).format("MM-DD-YYYY")}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </Layout>
  );
}

function Token({
  data,
  onPurchase,
  ...props
}: {
  data: CouponData;
  onPurchase: (data: CouponData) => any;
}) {
  console.log(data);
  return (
    <div className="buy-tokens-wrapper p-7">
      <div className="buy-token-box p-7 flex flex-col justify-center items-center gap-3">
        <h2>{data.couponPrefix}</h2>
        <h3>Bonus token: {data.creditToken}</h3>
        <button className="blue-bt w-full" onClick={() => onPurchase(data)}>
          Generate reseller coupon
        </button>
      </div>
    </div>
  );
}

export { Generatecoupon };
