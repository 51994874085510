import React, { useRef, useEffect, useState, useCallback } from "react";
import { Layout } from "components";
import { useMutation, useQuery } from "react-query";
import {
  getPresignedUrl,
  s3PutDocument,
  userDetails,
  queryKeys,
  documentTypeMap,
  manualDocumentEntry,
} from "constant";
import { useDropzone } from "react-dropzone";
import toast, { Toaster } from "react-hot-toast";
import { RiDeleteBin2Line } from "react-icons/ri";
import { MoonLoader } from "react-spinners";

function ManualVerify() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [getKey, setGetKey] = useState();
  const [fileData, setFileData] = useState({ name: "", type: "" });

  const documentIdRef: any = useRef("");

  const { data: userData } = useQuery(
    [queryKeys.USER_DETAILS, { path: window.location.pathname }],
    userDetails
  );

  const onDrop = useCallback(async (pendingImage, fileRejections) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === "file-too-large") {
          toast.error(`Error: File is learger than 500kb`);
        }

        if (err.code === "file-invalid-type") {
          toast.error(`Error: ${err.message}`);
        }
      });
    });

    if (fileRejections?.length) return;

    setFileData({ name: pendingImage[0].name, type: pendingImage[0].type });
    setSelectedFile(pendingImage[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    disabled: false,
    onDrop,
    maxSize: 512000,
  });

  const { mutate: manualDocumentEntryMutate, isLoading: loading1 } =
    useMutation({
      mutationFn: manualDocumentEntry,
      onSuccess: (data) => {
        toast.success("Document Submitted Successfully");
      },
      onError: () => {
        toast.error("Failed to Submit document");
      },
    });

  const { mutate: uploadToS3Mutate, isLoading: loading2 } = useMutation({
    mutationFn: s3PutDocument,
    onSuccess: (data) => {
      console.log("documentIdRef?.current vv", documentIdRef?.current?.value);
      manualDocumentEntryMutate({
        documentS3Key: getKey,
        documentId: documentIdRef?.current,
        companyId: userData?.data?.companyId,
        documentType: documentTypeMap.MANUAL,
      });
    },
    onError: () => {
      toast.error("Failed to upload document");
    },
  });

  const { mutate: getPresignAndSubmitMutate, isLoading: loading3 } =
    useMutation({
      mutationFn: getPresignedUrl,
      onSuccess: (data) => {
        if (data.data.key) {
          setGetKey(data.data.key);
          uploadToS3Mutate({
            presignedUrl: data.data.url,
            file: selectedFile,
            type: fileData.type,
          });
        } else {
          toast.error("Failed to upload document");
        }
      },
    });

  return (
    <Layout>
      <div
        style={{
          padding: "50px",
          color: "white",
          width: "85%",
        }}
      >
        <div style={{ display: "flex", padding: "10px" }}></div>
        <div style={{ marginTop: "20px", marginBottom: "20px" }}>
          <div
            {...getRootProps()}
            style={{
              border: "2px dashed #ccc",
              padding: "20px",
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            <input {...getInputProps()} />
            {selectedFile ? (
              <img
                src={URL.createObjectURL(selectedFile)}
                alt="Preview Uploaded file"
                style={{
                  maxWidth: "100%",
                  maxHeight: "200px",
                  marginBottom: "10px",
                }}
              />
            ) : (
              <div style={{ marginBottom: "10px" }}>No File Selected</div>
            )}
            <div style={{ display: "flex" }}>
              <div
                style={{
                  backgroundColor: "#F87171",
                  padding: "10px",
                  flex: "95%",
                }}
              >
                Select image from here
              </div>
              <div
                style={{
                  flex: "5%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid",
                  borderRadius: "4px",
                  marginLeft: "8px",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setSelectedFile(null);
                }}
              >
                <RiDeleteBin2Line size={25} />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* Input for last 4 digits of document ID */}
          <div>
            <label
              style={{
                color: "white",
                marginBottom: "5px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              Last 4 digits of document ID
            </label>
            <input
              type="text"
              maxLength={4}
              onChange={(e) => {
                documentIdRef.current = e.target.value;
              }}
              style={{
                padding: "10px",
                marginBottom: "10px",
                backgroundColor: "#555",
                color: "white",
                border: "1px solid #777",
                borderRadius: "5px",
                width: "250px",
              }}
            />
          </div>
          {/* Submit button */}
          <div
            onClick={() => {
              console.log("selectedFile. filedata: ", fileData);
              if (!fileData?.name || documentIdRef?.current?.length < 4) {
                toast.error(
                  "Please make sure file is selected and last 4 digit of document id is given"
                );
                return;
              }
              getPresignAndSubmitMutate({
                companyId: userData?.data?.companyId,
                fileName: fileData.name,
                fileType: fileData.type,
                bucketNameKey: "DOCUMENTS_BUCKET",
              });
            }}
            style={{
              cursor: "pointer",
              marginTop: "20px",
              backgroundColor: "#2F855A",
              color: "white",
              padding: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "250px",
              borderRadius: "5px",
            }}
          >
            {loading1 || loading2 || loading3 ? (
              <MoonLoader color="rgba(12, 15, 55, 1)" size="20" />
            ) : (
              "Submit As Verified"
            )}
          </div>
        </div>
      </div>
      {/* Toaster component remains unchanged */}
      <Toaster
        toastOptions={{
          success: {
            duration: 3000,
          },
        }}
      />
    </Layout>
  );
}

export { ManualVerify };
