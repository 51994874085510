import { DatePicker } from "components";
import {
  getPresignedUrl,
  getWalletTransactions,
  queryKeys,
  userDetails,
} from "constant";
import moment from "moment";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { ClockLoader } from "react-spinners";
import { IoMdArrowDropup, IoMdArrowDropdown } from "react-icons/io";
import { ImFileEmpty } from "react-icons/im";

function Transactions({
  onInvoiceDownload,
  isInvoiceDownloadLoading,
  ...props
}: {
  onInvoiceDownload: (item: any) => any;
  isInvoiceDownloadLoading: boolean;
}) {
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(0);
  const [LastEvaluatedKeys, setLastEvaluatedKey] = useState([]);
  const { data: user, isLoading }: any = useQuery(
    [queryKeys.USER_DETAILS],
    userDetails
  );

  const {
    data: walletTransactionsData,
    isLoading: isWalletTransactionsLoading,
  } = useQuery(
    [
      queryKeys.GET_TRANSACTIONS,
      {
        companyId: user?.data?.companyId,
        startTime: startTime !== 0 ? (startTime * 1000).toString() : "",
        endTime: endTime !== 0 ? (endTime * 1000).toString() : "",
        isOnlyInvoice: false,
        limit: 100,
        LastEvaluatedKey: LastEvaluatedKeys?.length
          ? LastEvaluatedKeys[LastEvaluatedKeys?.length - 1]
          : "",
      },
    ],
    getWalletTransactions,
    {
      enabled: !!user?.data?.userId?.length,
    }
  );

  return (
    <div>
      <div className="all-verified-documents-wrap flex p-7 mt-8 flex-col">
        <div className="flex justify-between items-center mb-6">
          <h2 className="">Transaction History</h2>
          <div className="flex items-center gap-2">
            {" "}
            <div className="flex flex-col filter-date">
              <h2 className="mb-2">Filter By Date: </h2>
              <DatePicker
                onChange={(dateRange) => {
                  setStartTime(dateRange.startDate);
                  setEndTime(dateRange.endDate);
                  console.log(dateRange);
                }}
              />
            </div>
          </div>
        </div>

        <div
          className="table-container w-full"
          style={{ height: "56vh", overflow: "auto" }}
        >
          <table className="w-full">
            <tbody>
              <tr className="head-bg">
                <th>Transaction Id</th>
                <th>Token due</th>
                <th>Token Debit/Credit</th>
                <th>Money spend</th>
                <th>Money due</th>
                <th>Status</th>
                <th>Message</th>
                <th>Timestamp</th>
                <th>Updated tokens</th>
                <th>Invoice</th>
              </tr>
              {walletTransactionsData?.data?.Items?.map((item) => {
                const {
                  createdAt,
                  currentTokens,
                  message,
                  moneyDue,
                  moneySpend,
                  status,
                  tokenCredit,
                  tokenDeduct,
                  tokenDue,
                  transactionId,
                } = item;

                return (
                  <tr>
                    <td>{transactionId}</td>
                    <td>{tokenDue}</td>
                    <td
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                        justifyContent: "center",
                      }}
                    >
                      {tokenCredit > 0 ? (
                        <>
                          <IoMdArrowDropup size={25} color="green" />{" "}
                          {tokenCredit}
                        </>
                      ) : tokenDeduct > 0 ? (
                        <>
                          <IoMdArrowDropdown size={25} color="red" />{" "}
                          {tokenDeduct}
                        </>
                      ) : (
                        0
                      )}
                    </td>
                    <td>{moneySpend}</td>
                    <td>{moneyDue}</td>
                    <td>{status}</td>
                    <td>{message}</td>
                    <td>{moment(createdAt).format("DD-MM-YYYY")}</td>
                    <td>{currentTokens}</td>
                    <td>
                      {moneySpend ? (
                        <button
                          onClick={() => onInvoiceDownload(item)}
                          disabled={isInvoiceDownloadLoading}
                          className="rounded px-3 py-2 bg-green-600 font-bold"
                        >
                          Download
                        </button>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>

            {walletTransactionsData?.data?.Items?.length == 0 && (
              <tr>
                <td colSpan={7}>
                  <div className="justify-center items-center flex-col flex">
                    <ImFileEmpty className="text-5xl mb-4" />
                    <div className=" font-bold text-xl">No records found</div>
                  </div>
                </td>
              </tr>
            )}
            {isWalletTransactionsLoading && (
              <tr>
                <td colSpan={10}>
                  <div
                    style={{
                      width: "100%",
                      height: "300px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <ClockLoader color="white" size="20" />{" "}
                  </div>
                </td>
              </tr>
            )}
          </table>
        </div>
      </div>

      <div className="flex back-next-bt-wrapper justify-end gap-3 items-end mt-4">
        <div
          className="flex items-center"
          onClick={() => {
            setLastEvaluatedKey((olds: any) => {
              const newArr = JSON.parse(JSON.stringify(olds));
              return newArr?.length ? newArr?.slice(0, -1) : newArr;
            });
          }}
          style={{
            backgroundColor: LastEvaluatedKeys?.length ? "#009ef7" : "#2b2b40",
            color: LastEvaluatedKeys?.length ? "white" : "#7e8299",
          }}
        >
          Back
        </div>
        <div
          onClick={() => {
            if (walletTransactionsData?.data?.LastEvaluatedKey) {
              setLastEvaluatedKey((olds) => {
                return [
                  ...olds,
                  walletTransactionsData?.data?.LastEvaluatedKey,
                ];
              });
            }
          }}
          className={`flex items-center`}
          style={{
            backgroundColor: walletTransactionsData?.data?.LastEvaluatedKey
              ? "#009ef7"
              : "#2b2b40",
            color: walletTransactionsData?.data?.LastEvaluatedKey
              ? "white"
              : "#7e8299",
          }}
        >
          Next
        </div>
      </div>
    </div>
  );
}

export { Transactions };
