import { Layout } from "components";
import React, { useRef, useState } from "react";
import Billing from "./Billing";
import BuyWalletTokens from "./BuyWalletTokens";
import { Transactions } from "./Transactions";
import { useMutation, useQuery } from "react-query";
import {
  doWalletRecharge,
  getPresignedUrl,
  getWalletDetails,
  queryKeys,
  userDetails,
} from "constant";
import toast, { Toaster } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { RiBillLine } from "react-icons/ri";
import { MdOutlineGeneratingTokens } from "react-icons/md";
import { GrTransaction } from "react-icons/gr";

function WalletPage() {
  const billing = "billing";
  const transactions = "transactions";
  const buyCoupons = "buy-tokens";
  const { state: tabState } = useLocation();

  const navigate = useNavigate();
  const linkRef: any = useRef();

  const [presentPage, setPresentPage] = useState(tabState || billing);
  const [downloadUrl, setDownloadUrl] = useState("");

  const { data: userData } = useQuery(
    [queryKeys.USER_DETAILS, { path: window.location.pathname }],
    userDetails
  );

  const {
    mutate: getPresignedUrlMutate,
    isLoading: isPresignedUrlMutateLoading,
  } = useMutation({
    mutationFn: getPresignedUrl,
    onSuccess: (data) => {
      if (data.message.includes("nable")) {
        toast.error(data.message);
      }

      // alert(JSON.stringify(data));
      setDownloadUrl(data?.data?.url);
      linkRef?.current?.click();
    },
    onError: () => {
      toast.error("Unable to get presigned url");
    },
  });

  function handleObtainInvoiceUrlDownload(item) {
    getPresignedUrlMutate({
      companyId: userData?.data?.companyId,
      getFileKey: item?.s3Key,
      bucketNameKey: "INVOICE_BUCKET",
    });
  }

  return (
    <Layout>
      <div className="p-7 body-bg">
        <div className="wallet-tabs flex justify-between text-white p-6">
          <button
            className={
              presentPage == billing
                ? "active flex items-center"
                : "flex items-center"
            }
            onClick={() => setPresentPage(billing)}
          >
            <RiBillLine className="mr-2" size="20" />
            Billing
          </button>
          <button
            className={
              presentPage == buyCoupons
                ? "active flex items-center"
                : "flex items-center"
            }
            onClick={() => setPresentPage(buyCoupons)}
          >
            <MdOutlineGeneratingTokens className="mr-2" size="20" />
            Buy tokens
          </button>
          <button
            className={
              presentPage == transactions
                ? "active flex items-center"
                : "flex items-center"
            }
            onClick={() => setPresentPage(transactions)}
          >
            <GrTransaction className="mr-2" size="20" />
            Transactions
          </button>
        </div>
        <br />
        {presentPage == billing && (
          <Billing
            onInvoiceDownload={handleObtainInvoiceUrlDownload}
            isInvoiceDownloadLoading={isPresignedUrlMutateLoading}
          />
        )}
        {presentPage == transactions && (
          <Transactions
            onInvoiceDownload={handleObtainInvoiceUrlDownload}
            isInvoiceDownloadLoading={isPresignedUrlMutateLoading}
          />
        )}
        {presentPage == buyCoupons && <BuyWalletTokens />}
      </div>

      {downloadUrl && (
        <div
          style={{
            position: "absolute",
            display: "hidden",
            left: "50vw",
            height: 100,
            backgroundColor: "green",
          }}
          className="flex flex-col items-center justify-center"
        >
          <a
            style={{
              color: "white",
              border: "2px solid white",
              borderRadius: 10,
              padding: 2,
            }}
            href={downloadUrl}
            onClick={() => setDownloadUrl("")}
            ref={linkRef}
          >
            Download invoice
          </a>
        </div>
      )}

      <Toaster
        toastOptions={{
          success: {
            duration: 3000,
          },
        }}
      />
    </Layout>
  );
}

export { WalletPage };
