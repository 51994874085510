import { Layout } from "components";
import {
  getAllTransactions,
  getNextInvoiceSerial,
  getPresignedUrl,
  getSkipInvoiceSerial,
  queryKeys,
  userDetails,
} from "constant";
import moment from "moment";
import { Transaction } from "pages/types";
import React, { useRef, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useMutation, useQuery } from "react-query";
import { ClockLoader } from "react-spinners";
import NextInvoiceSerial from "./NextInvoiceSerial";
import Modal from "react-responsive-modal";
import { Button } from "@radix-ui/themes";
import { ImFileEmpty } from "react-icons/im";

function AllTransactionsPage() {
  const [showNextInvoice, setShowNextInvoice] = useState(false);

  const {
    data: allTransactionsData,
    isLoading: isAllTransactionsDataLoading,
  }: any = useQuery([queryKeys.GET_ALL_TRANSACTIONS], getAllTransactions);

  //  Calculate the total revenue
  let totalRevenue = 0;
  allTransactionsData?.data?.Items?.forEach((transaction: Transaction) => {
    totalRevenue += Number(transaction.moneySpend);
  });

  return (
    <Layout>
      <div className="p-7 body-bg">
        <div className="flex flex-row gap-3" style={{ color: "white" }}>
          <span>Total revenue:</span>
          <span>{totalRevenue}</span>
        </div>

        <Button
          onClick={() => {
            setShowNextInvoice(true);
          }}
        >
          Show next invoice serial
        </Button>

        <div>
          <div className="all-verified-documents-wrap flex p-7 mt-8 flex-col">
            <div className="flex justify-between items-center mb-6">
              <h2 className="">Transaction History</h2>
              <div className="flex items-center gap-2"></div>
            </div>

            <div className="table-container w-full">
              <table className="w-full">
                <tbody>
                  <tr className="head-bg">
                    <th>Transaction Id</th>
                    <th>Token due</th>
                    <th>Token deduct</th>
                    <th>Token Credit</th>
                    <th>Money spend</th>
                    <th>Money due</th>
                    <th>Status</th>
                    <th>Message</th>
                    <th>Timestamp</th>
                    <th>Current tokens</th>
                  </tr>
                  {allTransactionsData?.data?.Items?.map(
                    (item: Transaction) => {
                      const {
                        createdAt,
                        currentTokens,
                        message,
                        moneyDue,
                        moneySpend,
                        status,
                        tokenCredit,
                        tokenDeduct,
                        tokenDue,
                        transactionId,
                      } = item;

                      return (
                        <tr>
                          <td>{transactionId}</td>
                          <td>{tokenDue}</td>
                          <td>{tokenDeduct}</td>
                          <td>{tokenCredit}</td>
                          <td>{moneySpend}</td>
                          <td>{moneyDue}</td>
                          <td>{status}</td>
                          <td>{message}</td>
                          <td>{moment(createdAt).format("DD-MM-YYYY")}</td>
                          <td>{currentTokens}</td>
                        </tr>
                      );
                    }
                  )}
                </tbody>

                {allTransactionsData?.data?.Items?.length == 0 && (
                  <tr>
                    <td colSpan={7}>
                      <div className="justify-center items-center flex-col flex my-5">
                        <ImFileEmpty className="text-5xl mb-4" />
                        <div className=" font-bold text-xl">
                          No records found
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
                {isAllTransactionsDataLoading && (
                  <tr>
                    <td colSpan={7}>
                      <div
                        style={{
                          width: "100%",
                          height: "300px",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <ClockLoader color="white" size="20" />{" "}
                      </div>
                    </td>
                  </tr>
                )}
              </table>
              di
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={showNextInvoice}
        onClose={() => {
          setShowNextInvoice(false);
        }}
      >
        <NextInvoiceSerial />
      </Modal>

      <Toaster
        toastOptions={{
          success: {
            duration: 3000,
          },
        }}
      />
    </Layout>
  );
}

export { AllTransactionsPage };
