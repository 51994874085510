const queryKeys = {
    USER_DETAILS: "user-details",
    DOCUMENT_ENTRIES: "document-entries",
    GET_CUSTOMERS: "get-customers",
    GET_STAFFS: "get-staffs",
    STAFF_DETAILS: "staff-details",
    GET_VERIFICATION_LINKS: "get-verification-links",
    GET_TRANSACTIONS: "get-all-transactions",
    GET_WALLET_DETAILS: "get-wallet-details",
    GET_LINKED_DOCUMENTS: "get-linked-documents",
    GET_COUPONS: "get-coupons",
    GET_COUPON_SALES: "get-coupon-sales",
    GET_ENDUSER_LINK_DETAILS: "get-enduser-link-details",
    GET_RESELLER_COUPONS: "get-reseller-coupons",
    GET_RESELLER_STATS: "get-reseller-stats",
    GET_RESELLER_SALES: "get-reseller-sales",
    GET_RESELLERS: "get-resellers",
    GET_ALL_TRANSACTIONS: "get-all-transactions",
    GET_NEXT_INVOICE_SERIAL: "get-next-invoice-serial",
    SKIP_INVOICE_SERIAL: "skip-invoice-serial",
    GET_MANUAL_DOCUMENT_URL: "get-manual-document-url",
};

export { queryKeys };
