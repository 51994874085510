import React, { useRef, useEffect, useState } from "react";
import { Layout } from "components";
import { useMutation, useQuery } from "react-query";
import {
  documentEntry,
  documentTypeMap,
  documentModeMap,
  userDetails,
  queryKeys,
  userRoles,
  getWalletDetails,
} from "constant";
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { MoonLoader, ClockLoader } from "react-spinners";
import toast, { Toaster } from "react-hot-toast";
import { DocumentDisplayCard } from "components/DocumentDisplayCard";

function AadharVerify() {
  const location = useLocation();
  console.log("location from adhaar verify", location);
  const [activeTab, setActiveTab] = useState(
    location.pathname.includes("aadhar-otp") ? "tab2" : "tab1"
  );
  const navigate = useNavigate();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const numRef: any = useRef();
  const [showData, setShowData]: any = useState();
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: userData } = useQuery(
    [queryKeys.USER_DETAILS, { path: window.location.pathname }],
    userDetails
  );

  const { refetch: refetchWalletDetails } = useQuery(
    [
      queryKeys.GET_WALLET_DETAILS,
      {
        companyId: userData?.data?.companyId,
      },
    ],
    getWalletDetails,
    {
      enabled: !!userData?.data?.userId?.length,
    }
  );

  const { mutate: mutateDocument, isLoading: isDocumentMutateLoading } =
    useMutation({
      mutationFn: documentEntry,
      onSuccess: (data) => {
        console.log("ssuuc daata", data);
        if (
          (data?.data?.mapId === documentTypeMap.AADHAR ||
            data?.data?.mapId === documentTypeMap.DL ||
            data?.data?.mapId === documentTypeMap.PAN ||
            data?.data?.mapId === documentTypeMap.DIGI_EAADHAR) &&
          data?.data?.verified === "true"
        ) {
          toast.success("Document verification successful");
          setShowData(data);
        } else if (data?.data?.mapId === documentTypeMap.DIGI_URL) {
          localStorage.setItem("digiRequestId", data?.data?.requestId);
          window.location.replace(data?.data?.url);
        } else {
          toast.error("Third Party Server Error");
        }
        refetchWalletDetails();
      },
      onError: () => {
        toast.error("Third Party Server Errorn");
      },
    });

  useEffect(() => {
    if (
      searchParams.get("confirmAuthorization") == "true" &&
      userData?.data?.companyId
    ) {
      mutateDocument({
        documentId: localStorage.getItem("digiRequestId"), //  here document id is requestId
        companyId: userData?.data?.companyId,
        documentType: documentTypeMap.DIGI_EAADHAR,
        mapId: documentTypeMap.DIGI_EAADHAR,
      });
      localStorage.removeItem("digiRequestId");
      setSearchParams({});
    }
  }, [userData]);

  const Tab1Content = () => (
    <div>
      <div className="verify-box w-9/12">
        <h2>Verify User</h2>
        <div className="adhaar-no-box">
          <h3>Enter Aadhaar Number</h3>

          <div
            className="adhaar-putin-box" // class commented as the input cannot be seen
          >
            <input
              className="border-2 w-full"
              ref={numRef}
              placeholder="Ex: 1234-5678-9012"
            />
            <p className="mt-1 mb-2">
              Enter 12 digit number for aadhaar verification.
            </p>
            <button
              onClick={() =>
                mutateDocument({
                  documentId: numRef?.current?.value,
                  companyId: userData?.data?.companyId,
                  documentType: documentTypeMap.AADHAR,
                  mapId: documentTypeMap.AADHAR,
                })
              }
              disabled={isDocumentMutateLoading}
              className="blue-bt mt-5"
            >
              {" "}
              {isDocumentMutateLoading ? (
                <div>
                  <ClockLoader color="white" size="20" />
                </div>
              ) : (
                "Basic Verify"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
  const Tab2Content = () => (
    <div>
      <div className="verify-box w-9/12">
        <h2>Verify User</h2>

        {/* <label>BASIC aadhar verification with aadhar number</label> */}

        {/* <div className="my-5">Verify with OTP</div> */}
        <button
          className="p-2 blue-bt digi-verify-bt"
          onClick={() =>
            mutateDocument({
              documentId: window.location.href, //  here document id is callback url
              companyId: userData?.data?.companyId,
              documentType: documentTypeMap.DIGI_URL,
              mapId: documentTypeMap.DIGI_URL,
            })
          }
          disabled={isDocumentMutateLoading}
        >
          {" "}
          {isDocumentMutateLoading ? (
            <div>
              <ClockLoader color="white" size="20" />
            </div>
          ) : (
            "Verify With DigiLocker"
          )}
        </button>
      </div>
    </div>
  );

  return (
    <Layout>
      <div className="veriication-detail-wrapper flex flex-col p-7">
        <div className="document-type-box flex p-4">
          <h2>Document Type - Aadhaar</h2>
        </div>

        <div>
          <div className="tabs-titles flex gap-4">
            <button
              onClick={() => {
                navigate("../verify/aadhar", { replace: true });
                handleTabClick("tab1");
                !(activeTab === "tab1") && setShowData(false);
              }}
              className={activeTab === "tab1" ? "active" : ""}
            >
              Aadhaar
            </button>
            <button
              onClick={() => {
                navigate("../verify/aadhar-otp", { replace: true });
                handleTabClick("tab2");
                !(activeTab === "tab2") && setShowData(false);
              }}
              className={activeTab === "tab2" ? "active" : ""}
            >
              Aadhaar (OTP)
            </button>
          </div>
          <div>
            {activeTab === "tab1" && <Tab1Content />}
            {activeTab === "tab2" && <Tab2Content />}
          </div>
        </div>
      </div>

      {showData ? (
        <DocumentDisplayCard
          doc={{
            addedBy: userData?.data,
            createdAt: Date.now(),
            mode:
              activeTab === "tab1"
                ? documentModeMap[documentTypeMap.AADHAR].mode
                : documentModeMap[documentTypeMap.DIGI_EAADHAR].mode,
            meta: showData?.data,
          }}
        />
      ) : null}

      <Toaster
        toastOptions={{
          success: {
            duration: 3000,
          },
        }}
      />
    </Layout>
  );
}

export { AadharVerify };
