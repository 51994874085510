import { Layout } from "components";
import React, { useEffect, useRef, useState } from "react";
import { StaffData } from "pages/types";
import { useMutation, useQuery } from "react-query";
import ReactDOM from "react-dom";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import {
  CreateStaffArgs,
  GetStaffsArgs,
  RemoveStaffArgs,
  createStaff,
  getStaffs,
  queryKeys,
  removeStaff,
  userDetails,
} from "constant";
import toast, { Toaster } from "react-hot-toast";
import Loader from "components/Loader";
import ConfirmationModal from "components/ConfirmationModal";
import { ClockLoader } from "react-spinners";
import { ImFileEmpty } from "react-icons/im";

interface Staff {
  name: string;
  phoneNumber: string;
  email: string;
  state: string;
  createdOn: string;
  action: string;
}

interface ServerStaff {
  companyId: string;
  isVerified: boolean;
  userId: string;
  name: string;
  role: string;
  emai: string;
  meta: {
    firstName: string;
    lastName: string;
    phone: string;
    state: string;
    email: string;
    address: string;
    createdAt: number;
  };
}

function StaffsPage() {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [modalPayload, setModalPayload] = useState<any>();

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => {
    setStaff(null);
    setOpen(false);
  };

  const onOpenModal2 = () => setOpen2(true);
  const onCloseModal2 = () => setOpen2(false);

  const [staff, setStaff] = useState<ServerStaff>();
  const [staffs, setStaffs] = useState<ServerStaff[]>([]);

  const { data: userData } = useQuery(
    [queryKeys.USER_DETAILS, { path: window.location.pathname }],
    userDetails
  );
  console.log(userData);

  const staffQueryArgs: GetStaffsArgs = {
    companyId: userData?.data?.companyId,
  };
  const {
    data: staffsData,
    refetch: refetchStaffsData,
    isLoading: isStaffsDataLoading,
  } = useQuery([queryKeys.GET_STAFFS, staffQueryArgs], getStaffs, {
    enabled: !!userData?.data?.userId?.length,
  });

  const { mutate: mutateDeleteStaff, isLoading: isStaffDeleting } = useMutation(
    {
      mutationFn: removeStaff,
      onSuccess: (data) => {
        console.log(data);
        if (data.message.includes("nable")) {
          toast.error(data.message);
        } else {
          toast.success(data.message);
        }
        refetchStaffsData();
      },
      onError: () => {
        toast.error("Failed to create staff.");
      },
    }
  );

  const { mutate: mutateCreateStaff, isLoading: isCreatingStaff } = useMutation(
    {
      mutationFn: createStaff,
      onSuccess: (data) => {
        console.log(data);
        if (data.message.includes("nable")) {
          toast.error(data.message);
        } else {
          toast.success(data.message);
        }
        refetchStaffsData();
        onCloseModal2();
      },
      onError: () => {
        toast.error("Failed to create staff.");
      },
    }
  );

  useEffect(() => {
    if (staffsData) {
      setStaffs(staffsData?.data?.Items);
    }

    console.log(staffs);
  }, [staffsData]);

  function handleStaffDeletion(staff: ServerStaff) {
    setConfirmationModal(true);
    setModalPayload(staff);
  }

  function handleViewStaff(staff: ServerStaff) {
    setStaff(staff);
    onOpenModal();
  }

  function handleStaffCreation(staff: CreatedStaff) {
    const staffObject: CreateStaffArgs = {
      companyId: userData?.data?.companyId,
      name: `${staff.firstName} ${staff.lastName}`,
      password: staff.password,
      phone: staff.phone,
      email: staff.email,
      meta: {
        firstName: staff.firstName,
        lastName: staff.lastName,
        phone: staff.phone,
        state: staff.state,
        email: staff.email,
        address: staff.address,
        createdAt: new Date().getTime(),
      },
    };
    mutateCreateStaff({ queryKey: ["", staffObject] });
  }

  return (
    <Layout>
      <div className="p-7 body-bg">
        <div className="all-verified-documents-wrap flex p-7 mt-8 flex-col">
          <div className="flex justify-between items-center mb-6">
            <h2>Staffs</h2>
            <a className="convert-bt flex items-center font-bold" onClick={onOpenModal2}>
              + Add staff
            </a>
          </div>

          {/* Table */}

          <div className="table-container w-full">
            <table className="w-full" style={{height: "66vh", overflow: "auto"}}>
              {/* Table Head */}
              <thead>
                <tr className="head-dashed-underline">
                  {/* Add your column headers here */}
                  <th className="uppercase text-left">Name</th>
                  <th className="uppercase">Phone number</th>
                  <th className="uppercase">Email</th>
                  <th className="uppercase">State</th>
                  <th className="uppercase">created on</th>
                  <th className="uppercase">verified</th>
                  <th className="uppercase">action</th>
                </tr>
              </thead>

              {/* Table Body */}
              <tbody>
                {staffs?.map((staff, index) => (
                  <tr key={index}>
                    {/* Add your row data here */}
                    <td className="px-6 py-4 whitespace-nowrap">
                      {staff?.name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {staff?.userId}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {staff?.meta?.email}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {staff?.meta?.state}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {new Date(staff?.meta?.createdAt)
                        .toDateString()
                        .substring(0, 11)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {staff?.isVerified ? "verified" : "not verified"}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex flex-col" style={{ gap: "0.2rem" }}>
                        <button
                          className="delete-bt flex items-center justify-center"
                          onClick={() => handleStaffDeletion(staff)}
                        >
                          Delete
                        </button>
                        <button
                          className="view flex items-center justify-center"
                          onClick={() => handleViewStaff(staff)}
                        >
                          View
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
                {staffs?.length == 0 && !isStaffsDataLoading && (
                  <tr>
                    <td colSpan={7}>
                      <div className="justify-center items-center flex-col flex my-5">
                        <ImFileEmpty className="text-5xl mb-4" />
                        <div className=" font-bold text-xl">
                          No records found
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
                {isStaffsDataLoading && (
                  <tr>
                    <td colSpan={7}>
                      <div
                        style={{
                          width: "100%",
                          height: "300px",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <ClockLoader color="white" size="20" />{" "}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div>
        <Modal open={open} onClose={onCloseModal} center>
          {staff && (
            <div className="modal-wrapper flex flex-col">
              <div className="modal-title flex p-6 w-full">
                View Staff Detials
              </div>
              <div className="modal-body flex flex-col p-6  w-full">
                <div className="flex items-center gap-7 p-3">
                  <span>Phone Number</span>
                  <p>{staff.userId}</p>
                </div>
                <div className="flex items-center gap-7 p-3">
                  <span>Staff Name</span>
                  <p>{staff.name}</p>
                </div>
                <div className="flex items-center gap-7 p-3">
                  <span>Email</span>
                  <p>{staff?.meta?.email}</p>
                </div>
                <div className="flex items-center gap-7 p-3">
                  <span>State</span>
                  <p>{staff?.meta?.state}</p>
                </div>
                <div className="flex items-center gap-7 p-3">
                  <span>Created On</span>
                  <p>
                    {new Date(staff?.meta?.createdAt)
                      .toDateString()
                      .substring(0, 11)}
                  </p>
                </div>
                <div className="flex items-center gap-7 p-3">
                  <span>Address</span>
                  <p>{staff?.meta?.address}</p>
                </div>
              </div>

              <div className="modal-footer flex justify-end p-6 w-full">
                <a
                  className="modal-close-bt flex items-center"
                  onClick={onCloseModal}
                >
                  Close
                </a>
              </div>
            </div>
          )}
        </Modal>

        <Modal open={open2} onClose={onCloseModal2} center>
          <CreateStaff
            onStaffCreation={(createdStaff) =>
              handleStaffCreation(createdStaff)
            }
            onCancel={onCloseModal2}
          />
        </Modal>
      </div>

      <Toaster
        toastOptions={{
          success: {
            duration: 3000,
          },
        }}
      />

      <ConfirmationModal
        payload={modalPayload}
        open={confirmationModal}
        onClose={() => setConfirmationModal(false)}
        message={"Are you sure"}
        onNo={() => setConfirmationModal(false)}
        onYes={(payload) => {
          console.log(payload);
          const deleteStaffArgs: RemoveStaffArgs = {
            companyId: payload.companyId,
            staffId: payload.userId,
          };

          mutateDeleteStaff({ queryKey: ["", deleteStaffArgs] });

          setConfirmationModal(false);
        }}
      />
    </Layout>
  );
}

interface CreatedStaff {
  firstName: string;
  lastName: string;
  phone: string;
  state: string;
  email?: string;
  address: string;
  password: string;
}
function CreateStaff(props: {
  onStaffCreation: (staff: CreatedStaff) => any;
  onCancel: () => any;
}) {
  const firstNameRef: any = useRef();
  const lastNameRef: any = useRef();
  const phoneRef: any = useRef();
  const stateRef: any = useRef();
  const emailRef: any = useRef();
  const addressRef: any = useRef();
  const passwordRef: any = useRef();

  function handleStaffCreation() {
    const staff: CreatedStaff = {
      firstName: firstNameRef?.current?.value,
      lastName: lastNameRef?.current?.value,
      phone: phoneRef?.current?.value,
      state: stateRef?.current?.value,
      email: emailRef?.current?.value,
      address: addressRef?.current?.value,
      password: passwordRef?.current?.value,
    };

    props.onStaffCreation(staff);
  }

  return (
    <div className="modal-wrapper flex flex-col">
      <div className="modal-title flex p-6 w-full">Add Staff</div>
      <div className="modal-body flex flex-col pt-0 p-6  w-full">
        <div className="convert-clients-wrap p-8">
          {/* <div className="grid grid-cols-1 gap-4">
            <div className="flex flex-col">
              <label htmlFor="" className="mb-2">
                First Name
              </label>
              <input type="text" placeholder="Enter client name" />
            </div>
          </div> */}

          <div className="grid grid-cols-2 md:grid-cols-2 gap-6 mt-6">
            <div className="flex flex-col">
              <label htmlFor="" className="mb-2">
                First Name
              </label>
              <input ref={firstNameRef} type="text" placeholder="Ex: Joe" />
            </div>
            <div className="flex flex-col">
              <label htmlFor="" className="mb-2">
                Last Name
              </label>
              <input ref={lastNameRef} type="text" placeholder="Ex: blanco" />
            </div>
            <div className="flex flex-col">
              <label htmlFor="" className="mb-2">
                Phone Number (Username)
              </label>
              <input ref={phoneRef} type="text" placeholder="Ex: 9012345678" />
            </div>
            <div className="flex flex-col">
              <label htmlFor="" className="mb-2">
                Password
              </label>
              <input ref={passwordRef} type="password" placeholder="" />
            </div>
            <div className="flex flex-col">
              <label htmlFor="" className="mb-2">
                Choose State
              </label>
              <select
                name="state"
                placeholder="Service Type"
                className="form-select form-control-lg"
                ref={stateRef}
              >
                <option value="">Select State</option>
                <option value="Andaman and Nicobar Islands">
                  Andaman and Nicobar Islands
                </option>
                <option value="Andhra Pradesh">Andhra Pradesh</option>
                <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                <option value="Assam">Assam</option>
                <option value="Bihar">Bihar</option>
                <option value="Chandigarh">Chandigarh</option>
                <option value="Chhattisgarh">Chhattisgarh</option>
                <option value="Dadra and Nagar Haveli">
                  Dadra and Nagar Haveli
                </option>
                <option value="Daman and Diu">Daman and Diu</option>
                <option value="Delhi">Delhi</option>
                <option value="Goa">Goa</option>
                <option value="Gujarat">Gujarat</option>
                <option value="Haryana">Haryana</option>
                <option value="Himachal Pradesh">Himachal Pradesh</option>
                <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                <option value="Jharkhand">Jharkhand</option>
                <option value="Karnataka">Karnataka</option>
                <option value="Kerala">Kerala</option>
                <option value="Lakshadweep">Lakshadweep</option>
                <option value="Madhya Pradesh">Madhya Pradesh</option>
                <option value="Maharashtra">Maharashtra</option>
                <option value="Manipur">Manipur</option>
                <option value="Meghalaya">Meghalaya</option>
                <option value="Mizoram">Mizoram</option>
                <option value="Nagaland">Nagaland</option>
                <option value="Odisha">Odisha</option>
                <option value="Puducherry">Puducherry</option>
                <option value="Punjab">Punjab</option>
                <option value="Rajasthan">Rajasthan</option>
                <option value="Sikkim">Sikkim</option>
                <option value="Tamil Nadu">Tamil Nadu</option>
                <option value="Telangana">Telangana</option>
                <option value="Tripura">Tripura</option>
                <option value="Uttar Pradesh">Uttar Pradesh</option>
                <option value="Uttarakhand">Uttarakhand</option>
                <option value="West Bengal">West Bengal</option>
              </select>
            </div>
            <div className="flex flex-col">
              <label htmlFor="" className="mb-2">
                Email
              </label>
              <input type="text" placeholder="Email" ref={emailRef} />
              <span className="inormation">
                We will send a credentails details on the mail.
              </span>
            </div>
            <div className="flex flex-col">
              <label htmlFor="" className="mb-2">
                Address
              </label>
              <input type="text" placeholder="Enter address" ref={addressRef} />
            </div>
          </div>
        </div>
      </div>

      <div className="modal-footer flex justify-end p-6 w-full gap-2">
        <a
          className="assign-bt flex items-center"
          onClick={handleStaffCreation}
        >
          Create
        </a>
        <a className="cancel-bt flex items-center" onClick={props.onCancel}>
          Discard
        </a>
      </div>
    </div>
  );
}

export { StaffsPage };
