import { userDetails, queryKeys, userRoles, getWalletDetails } from "constant";
import { UserWallet } from "pages/types";
import { useContext, useState } from "react";
import { useQuery } from "react-query";
// import { Link } from "react-router-dom";
import { Route, Routes, Link, useNavigate } from "react-router-dom";
import { GiTwoCoins } from "react-icons/gi";
import { IoSettingsSharp } from "react-icons/io5";

function Layout({ children }:any) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };
  const { data, isLoading }: any = useQuery(
    [queryKeys.USER_DETAILS],
    userDetails
  );
  const {
    data: walletDetailsData,
    isLoading: isWalletDetailsDataLoading,
    refetch: refetchWalletDetailsData,
    isFetching: isWalletDetailsFetching,
  } = useQuery(
    [
      queryKeys.GET_WALLET_DETAILS,
      {
        companyId: data?.data?.companyId,
      },
    ],

    getWalletDetails,
    {
      enabled: !!data?.data?.userId?.length,
    }
  );

  const role = data?.data?.role;
  // const role = userRoles.SUPER_ADMIN;

  // if (isLoading) return <div>Authenticating...</div>;

  console.log(role);

  let sidebarData = [];

  switch (role) {
    case userRoles.ADMIN:
      sidebarData = [
        {
          label: "Verify",
          href: "/verify",
          icon: <i className="bi bi-house"></i>,
        },
        {
          label: "Documents",
          href: "/documents",
          icon: <i className="bi bi-file-text fs-2"></i>,
        },
        {
          label: "Customers",
          href: "/customers",
          icon: <i className="bi bi-people-fill"></i>,
        },
        {
          label: "Staffs",
          href: "/staffs",
          icon: <i className="bi bi-person-badge"></i>,
        },
        {
          label: "Settings",
          href: "/account-settings",
          icon: <i className="bi bi-gear fs-2"></i>,
        },
        {
          label: "Wallet",
          href: "/wallet",
          icon: <i className="bi bi-credit-card"></i>,
        },
      ];

      break;
    case userRoles.STAFF:
      sidebarData = [
        {
          label: "Verify",
          href: "/verify",
          icon: <i className="bi bi-house"></i>,
        },
        {
          label: "Documents",
          href: "/documents",
          icon: <i className="bi bi-file-text fs-2"></i>,
        },
        {
          label: "Customers",
          href: "/customers",
          icon: <i className="bi bi-people-fill"></i>,
        },
        {
          label: "Wallet",
          href: "/wallet",
          icon: <i className="bi bi-credit-card"></i>,
        },
      ];

      break;
    case userRoles.RESELLER:
      sidebarData = [
        {
          label: "Home",
          href: "/sales-home",
          icon: <i className="bi bi-house fs-2"></i>,
        },
        {
          label: "Generate coupon",
          href: "/generate-coupon",
          icon: <i className="bi bi-file-earmark-medical"></i>,
        },
        {
          label: "Gifts",
          href: "/gift-request",
          icon: <i className="bi bi-gear fs-2"></i>,
        },
        {
          label: "Settings",
          href: "/account-settings",
          icon: <i className="bi bi-gear fs-2"></i>,
        },
      ];

      break;
    case userRoles.SUPER_ADMIN:
      sidebarData = [
        {
          label: "Home",
          href: "/",
          icon: <i className="bi bi-shop-window"></i>,
        },
        {
          label: "Resellers",
          href: "/resellers",
          icon: <i className="bi bi-shop-window"></i>,
        },
        {
          label: "Companies",
          href: "/companies",
          icon: <i className="bi bi-person-rolodex"></i>,
        },
        {
          label: "Create coupons",
          href: "/create-coupons",
          icon: <i className="bi bi-person-rolodex"></i>,
        },
        {
          label: "Gift requests",
          href: "/cash-requests",
          icon: <i className="bi bi-person-rolodex"></i>,
        },
        {
          label: "All transactions",
          href: "/all-transactions",
          icon: <i className="bi bi-person-rolodex"></i>,
        },
      ];

      break;

    default:
      break;
  }

  function handleSignOut() {
    localStorage.removeItem("accessToken");
    navigate("/login");
  }

  const [counter, setCounter] = useState(0);

  return (
    <div className="flex h-[100vh] w-full">
      <div className="h-full w-44 left-panel">
        <div className="left-logo">
          <a href="" className="flex items-center justify-center">
            <img src="/images/black-icon-logo.png" alt="" />
          </a>
        </div>

        <div className="left-menu">
          {sidebarData.map((item) => (
            <a
              // className="flex flex-col p-4 justify-center items-center"
              className={`${
                window.location.href.includes(item.href) ? "active-nav" : ""
              }`}
              onClick={() => {
                navigate(item.href);
              }}
            >
              <span
                className="flex flex-col w-20 rounded-md p-2"
                // style={{
                //   color: window.location.href.includes(item.href) && "blue",
                // }}
              >
                {/* <i className="bi bi-house"></i> */}
                {item.icon}
                <p className="p-0 text-center"> {item.label}</p>
              </span>
            </a>
          ))}
        </div>
      </div>
      <div className="w-full body-container h-screen h-full flex flex-col justify-between">
        <div className="flex flex-col">
          <div className="w-full bg-gray-300 flex flex-col justify-end dashboard-top-section-wrapper">
            <div className="profile-wrap w-full flex justify-end gap-4">
              {!isWalletDetailsDataLoading &&
                role !== userRoles.SUPER_ADMIN &&
                role !== userRoles.RESELLER && (
                  <div className="token-box flex items-center gap-1">
                    <GiTwoCoins className="text-yellow-300" size="26" />
                    <p className="font-bold">Tokens: </p>
                    <b>{walletDetailsData?.data?.Item?.tokens}</b>
                  </div>
                )}
              <div className="dropdown-wrap">
                <button onClick={handleClick}>
                  <i className="bi bi-person-fill"></i>
                </button>
                {isOpen && (
                  <div className="dropdown-list flex flex-col pt-4 pb-4">
                    <div className="pro-pic flex gap-4 pb-4">
                      <span className="flex items-center justify-center">
                        <i className="bi bi-person-fill"></i>
                      </span>
                      <div style={{ maxWidth: "160px" }}>
                        <h2>{data?.data?.name}</h2>
                        <p>{data?.data?.email}</p>
                      </div>
                    </div>
                    <a
                      onClick={() =>
                        navigate("/account-settings", {
                          state: "profile-menu",
                        })
                      }
                    >
                      My Profile
                    </a>
                    <a onClick={() => handleSignOut()}>Sign Out</a>
                  </div>
                )}
              </div>
            </div>

            <div className="w-full generate-link-wrap flex justify-between items-center p-3 pl-8 pe-6">
              <div className="page-title">
                <h2>Dashboard | {role}</h2>
              </div>
              {role !== userRoles.SUPER_ADMIN &&
                role !== userRoles.RESELLER && (
                  <div
                    className="generate-link-box flex items-center gap-4 px-4 py-2 border rounded-md border-neutral-600 cusrsor-pointer"
                    onClick={() => navigate("/verification-link")}
                  >
                    <IoSettingsSharp className="text-neutral-400 text-lg" />
                    <a className="">Generate Link</a>
                  </div>
                )}
            </div>
          </div>
          {children}
        </div>
        <footer className="flex justify-between p-4">
          <div className="copyright">
            {" "}
            <span>2023©</span>SpaceShift
          </div>
          <span className="foot-menu flex gap-4">
            <a href="">About</a>
            <a href="">Support</a>
          </span>
        </footer>
      </div>
    </div>
  );
}

export { Layout };
